import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import userWallet from "./../../assets/images/wallet.svg";
import userWithdraw from "./../../assets/images/withdraw.svg";
import userTransactions from "./../../assets/images/transactions.svg";
import buy from "./../../assets/images/buy.svg";
import swap from "./../../assets/images/swap.svg";
import rollover from "./../../assets/images/rollover.svg";
import bitHistory from "./../../assets/images/bit-history.svg";
import deposit from "./../../assets/images/deposit.svg";
import totalBalance from "./../../assets/images/total-balance.svg";
import rupeeIcon from "./../../assets/images/rupee_icon.svg";
import USDT from "./../../assets/images/usdt.svg";
import BTC from "./../../assets/images/btc.svg";
import BNB from "./../../assets/images/bnb.svg";
import ETH from "./../../assets/images/eth.svg";
import TURBO from "./../../assets/images/turbo.svg";
import USDC from "./../../assets/images/usdc.svg";
import AVAX from "./../../assets/images/avax.svg";
import CBANK from "./../../assets/images/cbank.svg";
import CAF from "./../../assets/images/caf.svg";
import cairo from "./../../assets/images/cairo.svg";
import thunderIcon from "./../../assets/images/thunder-icon.svg";
import giftBox from "./../../assets/images/gift.svg";
import barImage from "./../../assets/images/barCode.svg";
import EUR from "./../../assets/images/eur.svg";
import sepaInstant from "./../../assets/images/sepa-instant.svg";
import Euro from "./../../assets/images/euro.svg";
import swapIcon from "./../../assets/images/swap-icon.svg";
import COPY from "../../assets/images/copy.svg";
import fileEmpty from "../../assets/images/fileEmpty.svg";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAPIAuth, postAPIAuth } from "../../services/apiInstance";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { selectCrypto } from "../../store/action";
import { Spinner } from "react-bootstrap";

const WithdrawTab = () => {
  const { token } = useAuth();
  // const { walletModalObject } = useWalletModal()
  const cryptoList = useSelector((state) => state.cryptoList);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const userDetails = useSelector((state) => state.userDetails);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [availCrypto, setAvailCrypto] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [convertedPrice, setConvertedPrice] = useState(0.0);
  const timeoutRef = useRef();
  const { t } = useTranslation();
  const walletLabels = t("wallet", { returnObjects: true });
  const [allNetwork, setAllNewtWork] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [networkOpen, setNetworkOpen] = useState(false);
  const [networkFiltered, setNetworkFiltered] = useState([]);
  const [searchNetwork, setSearchNetwork] = useState("");
  const [selectedCurrencyBalance, setSelectedCurrencyBalance] = useState(null);
  const [allCrypto, setAllCrypto] = useState([]);
  const [selectedCryptoDetail, setSelectedCryptoDetail] = useState(null);
  const [fees, setFees] = useState(null);
  const [feeError, setFeeError] = useState("");
  const networkRef = useRef();
  // const [withdrawLoading, setWithdrawLoading] = useState(false)
  // const cryt
  const crypto = useSelector((state) => state?.selectedCurrency?.crypto);
  const userBalance = useSelector((state) => state.userBalance);

  useEffect(() => {
    if (crypto?._id) {
      setSelectedCrypto(crypto);
    }
  }, [crypto?._id]);

  // const userDetails = useSelector(state => state.userDetails)
  const getAvailableCrypto = async () => {
    try {
      const res = await getAPIAuth("crypto/get-crypt-favorite-by-admin");
      if (res.data.success) {
        // setAvailCrypto(res.data.data)
        const data = res?.data?.data?.filter((item) => item?.code !== "eth");
        setAvailCrypto(data);
        // setSelCryptoNetwork(res.data.data)
      }
    } catch (error) {}
  };

  //   function for filter network data
  const filterNetwork = () => {
    if (searchNetwork) {
      let filterData = allNetwork.filter((el) =>
        el.name.toUpperCase().includes(searchNetwork?.toUpperCase())
      );
      setNetworkFiltered(filterData);
    } else {
      setNetworkFiltered(allNetwork);
    }
  };
  const getCrypto = async () => {
    if (search) {
      // let filterDataArr = cryptoList.filter((el) => {
      //   return el.code.toUpperCase().includes(search.toUpperCase());
      // });
      const filterDataArr = allCrypto.filter(
        (item) =>
          item?.code.toLowerCase().includes(search.toLowerCase()) ||
          item?.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilterData(filterDataArr);
    } else {
      if (cryptoList.length === 0) {
        try {
          const resp = await getAPIAuth("crypto/get-crypto", token);
          // setCrypto(resp?.data.data)
          const data = resp?.data?.data?.filter((item) => item?.code !== "eth");
          if (!crypto?._id) {
            setSelectedCrypto(data[0]);
          }
          setFilterData(data);
          setAllCrypto(data);
          setAllNewtWork(data?.[0]?.allNetworks);
          setNetworkFiltered(data?.[0]?.allNetworks);
          setSelectedNetwork(data?.[0]?.allNetworks[0]);
        } catch (error) {}
      } else if (cryptoList.length !== 0) {
        const data = structuredClone(cryptoList)?.filter(
          (item) => item?.code !== "eth"
        );
        if (!crypto?._id) {
          setSelectedCrypto(data?.[0]);
        }
        setFilterData(data);
        setAllNewtWork(data?.[0]?.allNetworks);
        setNetworkFiltered(data?.[0]?.allNetworks);
        setSelectedNetwork(data?.[0]?.allNetworks[0]);
      }
      setIsLoading(false);
    }
  };

  const onWithdraw = async () => {
    setWithdrawLoading(true);
    if (!address) {
      errorToaster("Address can't be empty!");
      setWithdrawLoading(false);
      return;
    }
    if (!!!amount || amount === "") {
      errorToaster("Add a valid amount to withdraw!");
      setWithdrawLoading(false);
      return;
    }
    // if (convertedPrice === 0 || convertedPrice < 0) {
    //     errorToaster("Converted Amount isn't Valid")
    //     setWithdrawLoading(false)
    //     return
    // }
    try {
      const body = {
        userId: userDetails.id,
        address: address,
        withdraw_amount: amount,
        coinId: selectedCrypto._id,
        user_currency: userDetails?.usercurrency,
        chain: "eth",
        type: "withdraw",
        // convertedCurrency: convertedPrice,
        network: selectedNetwork?.name,
      };

      // const res = await postAPIAuth("user/save-request-withdraw", body)
      const res = await postAPIAuth("coin/withdrawRequest", body);

      if (res.data.success) {
        succesToaster(res.data.message);
      } else {
        errorToaster(res.data.message);
      }
    } catch (error) {
      errorToaster("Something went wrong");
    } finally {
      setTimeout(() => {
        setWithdrawLoading(false);
      }, 500);
      setAddress("");
      setAmount("");
    }
  };
  useEffect(() => {
    getCrypto();
  }, [search]);

  useEffect(() => {
    getAvailableCrypto();
  }, []);

  // useEffect(() => {
  //   if (walletModalObject.isWalletOpen &&
  //     walletModalObject.selectedTab === WALLET_MODAL_TABS.WITHDRAW) {
  //     // debouncing
  //     if (!!amount) {
  //       setWithdrawLoading(true)
  //     }
  //     if (timeoutRef.current) {
  //       clearTimeout(timeoutRef.current)
  //     }
  //     timeoutRef.current = setTimeout(() => {
  //       if (!!amount) {
  //         // priceConverter()
  //       } else {
  //         if (!amount) setConvertedPrice(0.00)
  //       }
  //       setWithdrawLoading(false)
  //     }, 400)
  //   }

  //   return () => clearTimeout(timeoutRef.current)
  // }, [amount, walletModalObject.selectedTab, walletModalObject.isWalletOpen, selectedCrypto])

  useEffect(() => {
    if (!!selectedCrypto?.allNetworks) {
      setAllNewtWork(selectedCrypto?.allNetworks);
      setNetworkFiltered(selectedCrypto?.allNetworks);
      setSelectedNetwork(selectedCrypto?.allNetworks[0]);
    }
  }, [selectedCrypto]);

  useEffect(() => {
    filterNetwork();
  }, [searchNetwork]);

  const getUserCurrencyAmount = async () => {
    try {
      const res = await getAPIAuth(
        `crypto/crypto-wallet?cryptoId=${selectedCrypto?._id}`
      );
      if (res?.data?.success) {
        setSelectedCurrencyBalance(res?.data?.data);
        setSelectedCryptoDetail(res?.data?.crypto_data);
      }
    } catch (error) {}
  };

  const checkWithdrwalAmount = async () => {
    try {
      const res = await postAPIAuth("checkUserWithdrawalAmount", {
        coinId: selectedCryptoDetail._id,
        amount: Number(amount),
      });

      if (res.data.status) {
        setFees(res.data.data);
        setFeeError("");
      } else {
        setFeeError(res.data.message);
        setFees(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedCryptoDetail && amount) {
      checkWithdrwalAmount();
    }
  }, [selectedCryptoDetail, amount]);

  useEffect(() => {
    if (selectedCrypto?._id) {
      getUserCurrencyAmount();
    }
  }, [selectedCrypto]);

  const handleCopy = () => {
    navigator.clipboard.writeText(address)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <>
      <div className="withdraw_sec">
        <div className="withdraw_row p-3 bg_main_color">
          <div className="mb-3">
            <label for="" className="form-label" style={{ color: "#6a798c" }}>
              Withdraw Currency
            </label>
            <div className="select_drop">
              <div className="btn-group w-100">
                <button
                  className="d-flex justify-content-between text-uppercase px-3 py-2 rounded_bg align-items-center border-0 shadow-none dropdown-toggle w-100 rounded-pill"
                  type="button"
                  id="defaultDropdown"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="true"
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center gap-2 text-white">
                    <div className="crypto-icon">
                      {selectedCrypto ? (
                        <img
                          src={selectedCrypto.icon}
                          alt=""
                          className="img-fluid"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {selectedCrypto?.code?.toUpperCase()}
                  </div>
                </button>
                <ul
                  className="dropdown-menu w-100 rounded_bg p-3"
                  aria-labelledby="defaultDropdown"
                >
                  <div className="searchInpDrop position-relative">
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                    <button
                      type="button"
                      className="searchImgg border-0 shadow-none p-0 m-0 bg-transparent"
                    >
                      <svg
                        stroke="#93A6C4"
                        height="22px"
                        widths="22px"
                        fill="#93A6C4"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
                      </svg>
                    </button>
                  </div>
                  <div className="currency_search_list overflow-y-auto">
                    <ul className="m-0  d-flex flex-column gap-2 p-2 mt-3">
                      {filterData.map((item) => {
                        return (
                          <li
                            onClick={() => {
                              setSelectedCrypto(item);
                              dispatch(selectCrypto(item));
                            }}
                            key={item._id}
                            className="cursor-pointer d-flex justify-content-between align-items-center dropList"
                          >
                            <div className="d-flex align-items-center gap-2">
                              <div className="crypto-icon">
                                <img
                                  src={item.icon}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              {item?.code.toUpperCase()}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {/* <div className="dropFooter mx-1 mb-1 d-flex align-items-center justify-content-between">
                    <div className="footerTxt text-white fw-bold">
                      Hide 0 Balance
                    </div>
                    <div>
                      <form className="">
                        <div className="footerToggle form-check form-switch">
                          <input
                            type="checkbox"
                            id="custom-switch"
                            className="form-check-input"
                          />
                        </div>
                      </form>
                    </div>
                  </div> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label for="" className="form-label" style={{ color: "#6a798c" }}>
              Choose Network
            </label>
            <div className="select_drop">
              <div className="btn-group w-100">
                <button
                  className="d-flex justify-content-between text-uppercase px-3 py-2 rounded_bg align-items-center border-0 shadow-none dropdown-toggle w-100 rounded-pill"
                  type="button"
                  id="defaultDropdown"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="true"
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center gap-2 text-white">
                    {selectedNetwork ? selectedNetwork.name.toUpperCase() : ""}
                  </div>
                </button>
                <ul
                  className="dropdown-menu w-100 rounded_bg p-3"
                  aria-labelledby="defaultDropdown"
                >
                  <div className="searchInpDrop position-relative">
                    <input type="text" placeholder="Search" />
                    <button
                      type="button"
                      className="searchImgg border-0 shadow-none p-0 m-0 bg-transparent"
                    >
                      <svg
                        stroke="#93A6C4"
                        height="22px"
                        widths="22px"
                        fill="#93A6C4"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
                      </svg>
                    </button>
                  </div>
                  <div className="currency_search_list overflow-y-auto">
                    <ul className="m-0  d-flex flex-column gap-2 p-2 mt-3">
                      {allNetwork.map((item) => {
                        return (
                          <li
                            onClick={() => {
                              setSelectedNetwork(item);
                              setNetworkOpen(!networkOpen);
                            }}
                            key={item._id}
                            className="cursor-pointer d-flex justify-content-between align-items-center dropList"
                          >
                            <div className="d-flex align-items-center gap-2">
                              {item.name.toUpperCase()}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="dropFooter mx-1 mb-1 d-flex align-items-center justify-content-between">
                    <div className="footerTxt text-white fw-bold">
                      Hide 0 Balance
                    </div>
                    <div>
                      <form className="">
                        <div className="footerToggle form-check form-switch">
                          <input
                            type="checkbox"
                            id="custom-switch"
                            className="form-check-input"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label for="" className="form-label" style={{ color: "#6a798c" }}>
              Withdrawal Address({selectedCrypto?.code?.toUpperCase()}
              {selectedCrypto?.code ? " - " : ""}
              {selectedNetwork?.name?.toUpperCase()})
            </label>
            <input
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              type="text"
              className="rounded_bg border-0 shadow-none rounded-pill px-3 py-2 w-100 text-white"
              id=""
            />
          </div>
          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between gap-3">
              <label for="" className="form-label" style={{ color: "#6a798c" }}>
                Withdraw amount
              </label>
              <div style={{ fontSize: "14px" }}>
                Min:{" "}
                <span className="deposit_txt" style={{ fontSize: "14px" }}>
                  {selectedCryptoDetail?.min_withdrawal_amount
                    ? selectedCryptoDetail?.min_withdrawal_amount
                    : 0}
                </span>
              </div>
            </div>
            <input
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) return;
                setAmount(e.target.value);
              }}
              value={amount}
              type="text"
              className="rounded_bg border-0 shadow-none rounded-pill px-3 py-2 w-100 text-white"
              id=""
            />

            <p className="text-danger mt-3 ">{feeError}</p>
            <div className="min_max_tab mt-3">
              <ul className="p-0 m-0 row gy-2">
                <li className="col-lg-3 col-md-3 col-6 px-1">
                  <button
                    onClick={() => {
                      if (selectedCryptoDetail) {
                        setAmount(selectedCryptoDetail?.min_withdrawal_amount);
                      }
                    }}
                    type="button"
                    className="w-100 border-0 shadow-none py-2 px-3 rounded-pill min-max-button "
                  >
                    MIN
                  </button>
                </li>
                <li className="col-lg-3 col-md-3 col-6 px-1">
                  <button
                    onClick={() => {
                      if (selectedCurrencyBalance) {
                        setAmount(selectedCurrencyBalance?.totalBalance * 0.25);
                      }
                    }}
                    type="button"
                    className="w-100 border-0 shadow-none py-2 px-3 rounded-pill min-max-button"
                  >
                    25%
                  </button>
                </li>
                <li className="col-lg-3 col-md-3 col-6 px-1">
                  <button
                    onClick={() => {
                      if (selectedCurrencyBalance) {
                        setAmount(selectedCurrencyBalance?.totalBalance * 0.5);
                      }
                    }}
                    type="button"
                    className="w-100 border-0 shadow-none py-2 px-3 rounded-pill min-max-button"
                  >
                    50%
                  </button>
                </li>
                <li className="col-lg-3 col-md-3 col-6 px-1">
                  <button
                    onClick={() => {
                      if (selectedCurrencyBalance) {
                        setAmount(selectedCurrencyBalance?.totalBalance);
                      }
                    }}
                    type="button"
                    className="w-100 border-0 shadow-none py-2 px-3 rounded-pill min-max-button"
                  >
                    MAX
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="mb-3">
              <div className="text-white withdraw-available pb-3">
                {" "}
                Available :{" "}
                {selectedCurrencyBalance
                  ? selectedCurrencyBalance?.totalBalance?.toFixed(2)
                  : 0.0}{" "}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="text-white">Withdrawal Amount</div>
              <div className="text-white">
                {/* <Link className="" style={{ color: "#6a798c" }}>
                  Locked funds:{" "}
                </Link> */}
                <span className="text-white">
                  {fees ? fees?.withdarwAmount.toFixed(2) : 0}{" "}
                  {selectedCryptoDetail?.name}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="text-white">Withdrawal Fees</div>
              <div className="text-white">
                {/* <Link className="" style={{ color: "#6a798c" }}>
                  Locked funds:{" "}
                </Link> */}
                <span className="text-white">
                  {fees ? fees?.withdrawFee.toFixed(2) : 0}{" "}
                  {selectedCryptoDetail?.name}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="text-white">Net Withdrawal Amount</div>
              <div className="text-white">
                {/* <Link className="" style={{ color: "#6a798c" }}>
                  Locked funds:{" "}
                </Link> */}
                <span className="text-white">
                  {fees ? fees?.netWithdrawAmount.toFixed(2) : 0}{" "}
                  {selectedCryptoDetail?.name}
                </span>
              </div>
            </div>
          </div>

          <div className="col-12">
            <button
              // onClick={onWithdraw}
              disabled={
                feeError ||
                !amount ||
                !address ||
                !selectedCrypto ||
                !selectedNetwork
                  ? true
                  : false
              }
              type="button"
              className={`cursor-pointer rounded-pill border-0 shadow-none text-white py-2 px-3 text-center w-100 ${
                feeError ||
                !amount ||
                !address ||
                !selectedCrypto ||
                !selectedNetwork
                  ? "preview-disable"
                  : ""
              }`}
              style={{
                background: "linear-gradient(45deg, #3dacff, #21a0ff)",
              }}
              data-bs-toggle="modal"
              data-bs-target="#withdrawConfirmationModal"
            >
              Preview
            </button>

            {/* {withdrawLoading ? (
              <Spinner animation="border" variant="dark" size="sm" />
            ) : (
              "Withdraw"
            )} */}
          </div>
          {/* <!-- Modal --> */}
          <div
            class="modal fade"
            id="withdrawConfirmationModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog  modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Withdrawal Confirmation
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div>
                    <div className="d-flex align-items-center justify-content-between confirm-tabs mb-2 pb-2 gap-2">
                      <h6 className="text-nowrap">Withdraw Currency</h6>
                      <p className="mb-0">
                        {selectedCryptoDetail?.name?.toUpperCase()}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between confirm-tabs mb-2 pb-2 gap-2">
                      <h6 className="text-nowrap">Currency Network</h6>
                      <p className="mb-0">
                        {selectedNetwork?.name?.toUpperCase()}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between confirm-tabs mb-2 pb-2 gap-2">
                      <h6 className="text-nowrap">Withdrawal wallet address</h6>
                      <div className="d-flex align-items-center justify-content-between copy-text ">
                        <div className="copy-text">{address}</div>
                        <div  onClick={handleCopy} className="cursor-pointer">
                          <img src={COPY} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-3">
                    <div className="amount-fees">Withdrawal amount & Fees</div>
                    <div className="withdraw-wrap-outer p-2">
                      <div className="withdraw-wrap1">
                        <div className="d-flex align-items-center justify-content-between withdraw-wrap">
                          <h6>Withdrawal Amount</h6>
                          <p>
                            {fees ? fees?.withdarwAmount.toFixed(2) : 0}{" "}
                            {selectedCryptoDetail?.name}
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between withdraw-wrap">
                          <h6>Withdrawal Fees</h6>
                          <p>
                            {fees ? fees?.withdrawFee.toFixed(2) : 0}{" "}
                            {selectedCryptoDetail?.name}
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between withdraw-wrap">
                          <h6>Gas Fee if any</h6>
                          <p>0</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between withdraw-wrap">
                        <h6>Net Withdrawal Amount</h6>
                        <p>
                          {fees ? fees?.netWithdrawAmount.toFixed(2) : 0}{" "}
                          {selectedCryptoDetail?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-12 mb-sm-0 mb-2 ">
                      <div>
                        <button 
                        
                        onClick={onWithdraw}
                        className="confirm-btn">
                          CONFIRM WITHDRAW
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12 mb-sm-0 mb-2">
                      <div>
                        <button data-bs-dismiss="modal" className="cancel-btn">
                          CANCEL WITHDRAW
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="coins_box rounded-4 p-3 text-white d-flex align-items-center gap-3 mt-3"
            style={{ background: "#405129" }}
          >
            <span>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                version="1.1"
                viewBox="0 0 16 16"
                height="24px"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 1.5c-1.736 0-3.369 0.676-4.596 1.904s-1.904 2.86-1.904 4.596c0 1.736 0.676 3.369 1.904 4.596s2.86 1.904 4.596 1.904c1.736 0 3.369-0.676 4.596-1.904s1.904-2.86 1.904-4.596c0-1.736-0.676-3.369-1.904-4.596s-2.86-1.904-4.596-1.904zM8 0v0c4.418 0 8 3.582 8 8s-3.582 8-8 8c-4.418 0-8-3.582-8-8s3.582-8 8-8zM7 11h2v2h-2zM7 3h2v6h-2z"></path>
              </svg>
            </span>
            For security purposes, large or suspicious withdrawal may take 1-6
            hours for audit process. We appreciate your patience!
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawTab;
