import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import userWallet from "./../../assets/images/wallet.svg";
import userWithdraw from "./../../assets/images/withdraw.svg";
import userTransactions from "./../../assets/images/transactions.svg";
import buy from "./../../assets/images/buy.svg";
import swap from "./../../assets/images/swap.svg";
import rollover from "./../../assets/images/rollover.svg";
import bitHistory from "./../../assets/images/bit-history.svg";
import deposit from "./../../assets/images/deposit.svg";
import totalBalance from "./../../assets/images/total-balance.svg";
import rupeeIcon from "./../../assets/images/rupee_icon.svg";
import USDT from "./../../assets/images/usdt.svg";
import BTC from "./../../assets/images/btc.svg";
import BNB from "./../../assets/images/bnb.svg";
import ETH from "./../../assets/images/eth.svg";
import TURBO from "./../../assets/images/turbo.svg";
import USDC from "./../../assets/images/usdc.svg";
import AVAX from "./../../assets/images/avax.svg";
import CBANK from "./../../assets/images/cbank.svg";
import CAF from "./../../assets/images/caf.svg";
import cairo from "./../../assets/images/cairo.svg";
import thunderIcon from "./../../assets/images/thunder-icon.svg";
import giftBox from "./../../assets/images/gift.svg";
import barImage from "./../../assets/images/barCode.svg";
import EUR from "./../../assets/images/eur.svg";
import sepaInstant from "./../../assets/images/sepa-instant.svg";
import Euro from "./../../assets/images/euro.svg";
import swapIcon from "./../../assets/images/swap-icon.svg";
import fileEmpty from "../../assets/images/fileEmpty.svg";
import DisputeWalletModal from "./DisputeWalletModal";
import { getAPIAuth } from "../../services/apiInstance";
import { useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { custom } from "viem";
const transactionType = [
  {
    type: "Deposit",
    value: "deposit",
  },
  {
    type: "Withdraw",
    value: "withdraw",
  },
  {
    type: "Swap",
    value: "swap",
  },
  {
    type: "Buy Crypto",
    value: "crypto",
  },
  {
    type: "Bonus",
    value: "bonus",
  },
];

const transactionsDate = [
  {
    type: "Past 30 Days",
    value: "30",
  },
  {
    type: "Past 60 Days",
    value: "60",
  },
  {
    type: "Past 7 Days",
    value: "7",
  },
  {
    type: "Past 24 Hours",
    value: "24h",
  },
  {
    type: "Past 90 Days",
    value: "90",
  },
];

const transectionDepositeCase = [
  {
    type: "All",
    value: "all",
  },
  {
    type: "Credited But not confirmed",
    value: "credited but not confirmed",
  },
  {
    type: "Success",
    value: "success",
  },
  {
    type: "Failed",
    value: "failed",
  },
  {
    type: "Waiting for Refund",
    value: "waiting for refund",
  },
  {
    type: "Refunding",
    value: "refunding",
  },
  {
    type: "Refunded",
    value: "refunded",
  },
];

const transectionWithdrawCase = [
  {
    type: "All",
    value: "all",
  },
  {
    type: "pending",
    value: "0",
  },
  {
    type: "approved",
    value: "1",
  },
  {
    type: "rejected",
    value: "2",
  },
  {
    type: "failed",
    value: "5",
  },
  {
    type: "review failed",
    value: "10",
  },
  {
    type: "transfer failed",
    value: "20",
  },
];

const TransactionTab = () => {
  const userDetails = useSelector((state) => state.userDetails);
  const { token } = useAuth();
  const [availableCryptoSearach, setAvailableCryptoSearch] = useState("");
  const [selectedTransactionType, setSelectedTransactionType] = useState({
    type: "Deposit",
    value: "deposit",
  });
  const [selectedTransactionDate, setSelectedTransactionDate] = useState({
    type: "Past 24 Hours",
    value: "24h",
  });
  const [selectedTraasnactionStatus, setSelectedTransactionStatus] = useState({
    type: "All",
    value: "all",
  });
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetsList, setAssestList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [showDateDropDown, setShowDropDown] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const getAvaliableCrypto = async () => {
    try {
      const res = await getAPIAuth(
        `crypto/get-crypto?userId=${
          userDetails?.id
        }&crypto=${availableCryptoSearach.toUpperCase()}`,
        token
      );

      if (res.data.success) {
        setAssestList(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserTransactions = async () => {
    try {
      setLoading(true);
      let url = `user/getUserTransactions/${selectedTransactionType?.value}?`;
      if (selectedAsset) {
        url = `${url}&asset=${selectedAsset?.name}`;
      }
      if (selectedTraasnactionStatus) {
        url = `${url}&status=${selectedTraasnactionStatus?.value}`;
      }
      if (selectedTransactionDate) {
        if (selectedTransactionDate.value === "custom") {
          url = `${url}&startDate=${startDate}&endDate=${endDate}`;
        } else {
          url = `${url}&dateFilter=${selectedTransactionDate?.value}`;
        }
      }

      const res = await getAPIAuth(url, token);
      if (res.data.success) {
        setTransactionList(res.data.data);
        if (res.data.data.length) {
          setTotalPages(Math.ceil(res.data.totalItems / 10));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserTransactions();
  }, [
    selectedTransactionType,
    selectedTraasnactionStatus,
    selectedAsset,
    selectedTransactionDate,
  ]);

  useEffect(() => {
    if (userDetails) {
      getAvaliableCrypto();
    }
  }, [availableCryptoSearach, userDetails]);

  useEffect(() => {
    if (selectedTransactionType?.value === "deposit") {
      setSelectedTransactionStatus(transectionDepositeCase[0]);
    }
    if (selectedTransactionType?.value === "withdraw") {
      setSelectedTransactionStatus(transectionWithdrawCase[0]);
    }
  }, [selectedTransactionType]);

  useEffect(() => {
    if (startDate && endDate && !showDateDropDown) {
      setSelectedTransactionDate({ type: "Custom", value: "custom" });
    }
    if (startDate && endDate) {
      setSelectedTransactionDate({ type: "Custom", value: "custom" });
    }
  }, [startDate, endDate, showDateDropDown]);

  console.log({ totalPages, page });
  return (
    <>
      <div className="transactions_sec">
        <div className="transactions_row p-3 bg_main_color">
          <div className="row align-items-center g-4">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="select_drop">
                <div className="btn-group w-100">
                  <button
                    className="d-flex justify-content-between  px-3 py-2 rounded_bg align-items-center border-0 shadow-none dropdown-toggle w-100 rounded-pill"
                    type="button"
                    id="defaultDropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center gap-2 text-white">
                      {selectedTransactionType?.type}
                    </div>
                  </button>
                  <ul
                    className="dropdown-menu w-100 rounded_bg p-3"
                    aria-labelledby="defaultDropdown"
                  >
                    <div>
                      <div className="currency_search_list overflow-y-auto">
                        <ul className="m-0  d-flex flex-column gap-2 p-0 mt-3">
                          {transactionType.map((item) => {
                            return (
                              <li
                                onClick={() => setSelectedTransactionType(item)}
                                key={item.value}
                                className={`cursor-pointer d-flex justify-content-between align-items-center dropList ${
                                  item.value === selectedTransactionType?.value
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  {item.type}
                                </div>
                                <div
                                  className={`radio-btn  ${
                                    item.value ===
                                    selectedTransactionType?.value
                                      ? "radio-checked"
                                      : ""
                                  } `}
                                >
                                  <div className="radio-outer">
                                    <div className="radio-inner"></div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="select_drop">
                <div className="btn-group w-100">
                  <button
                    className="d-flex justify-content-between  px-3 py-2 rounded_bg align-items-center border-0 shadow-none dropdown-toggle w-100 rounded-pill"
                    type="button"
                    id="defaultDropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center gap-2 text-white">
                      {selectedAsset?.icon ? (
                        <img src={selectedAsset?.icon} alt="" />
                      ) : (
                        ""
                      )}
                      {selectedAsset?.name ? selectedAsset?.name : "All Assets"}
                    </div>
                  </button>
                  <ul
                    className="dropdown-menu w-100 rounded_bg p-3"
                    aria-labelledby="defaultDropdown"
                  >
                    <div className="searchInpDrop position-relative">
                      <input
                        onChange={(e) =>
                          setAvailableCryptoSearch(e.target.value)
                        }
                        type="text"
                        placeholder="Search"
                      />
                      <button
                        type="button"
                        className="searchImgg border-0 shadow-none p-0 m-0 bg-transparent"
                      >
                        <svg
                          stroke="#93A6C4"
                          height="22px"
                          widths="22px"
                          fill="#93A6C4"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div className="currency_search_list overflow-y-auto">
                        <ul className="m-0  d-flex flex-column gap-2 p-0 mt-3">
                          {assetsList.map((item) => {
                            return (
                              <li
                                onClick={() => setSelectedAsset(item)}
                                key={item._id}
                                className={`cursor-pointer d-flex justify-content-between align-items-center dropList ${
                                  item.name === selectedAsset?.name
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <img src={item.icon} alt="" />
                                  {item.name}
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                    checked={
                                      item.name === selectedAsset?.name
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="select_drop">
                <div className="btn-group w-100 relative">
                  {showDateDropDown ? (
                    <div
                      className="custom-backdrop"
                      onClick={() => setShowDropDown(false)}
                    />
                  ) : (
                    <></>
                  )}
                  <button
                    onClick={() => setShowDropDown((prv) => !prv)}
                    className={`d-flex justify-content-between  px-3 py-2 rounded_bg align-items-center border-0 shadow-none dropdown-toggle w-100 rounded-pill  ${
                      showDateDropDown ? "show" : ""
                    }  `}
                    type="button"
                    id="defaultDropdown"
                  >
                    <div className="d-flex align-items-center gap-2 text-white">
                      {selectedTransactionDate?.type}
                    </div>
                  </button>
                  <ul
                    className={`dropdown-menu w-100 rounded_bg p-3  ${
                      showDateDropDown ? "show" : ""
                    } `}
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      margin: "0px",
                      transform: "translate3d(0px, 48px, 0px)",
                    }}
                  >
                    <div>
                      <div className="currency_search_list overflow-y-auto">
                        <ul
                          className={`m-0  d-flex flex-column gap-2 p-0 mt-3`}
                        >
                          {transactionsDate.map((item) => {
                            return (
                              <li
                                onClick={() => setSelectedTransactionDate(item)}
                                key={item.value}
                                className={`cursor-pointer d-flex justify-content-between align-items-center dropList ${
                                  item.value === selectedTransactionDate?.value
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  {item.type}
                                </div>
                                <div
                                  className={`radio-btn  ${
                                    item.value ===
                                    selectedTransactionDate?.value
                                      ? "radio-checked"
                                      : ""
                                  } `}
                                >
                                  <div className="radio-outer">
                                    <div className="radio-inner"></div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                          <li
                            className={`cursor-pointer d-flex justify-content-between align-items-center dropList `}
                          >
                            <div className="d-flex align-items-center gap-2">
                              <DatePicker
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                  setDateRange(update);
                                }}
                                maxDate={new Date()}
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select a date"
                                selectsRange={true}
                                isClearable={true}
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            {selectedTransactionType?.value === "deposit" ||
            selectedTransactionType?.value === "withdraw" ? (
              <div className="col-lg-4 col-md-6 col-12">
                <div className="select_drop">
                  <div className="btn-group w-100">
                    <button
                      className="d-flex justify-content-between  px-3 py-2 rounded_bg align-items-center border-0 shadow-none dropdown-toggle w-100 rounded-pill"
                      type="button"
                      id="defaultDropdown"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="true"
                      aria-expanded="false"
                    >
                      <div className="d-flex align-items-center gap-2 text-white">
                        {selectedTraasnactionStatus
                          ? selectedTraasnactionStatus.type
                          : "All Status"}
                      </div>
                    </button>
                    <ul
                      className="dropdown-menu w-100 rounded_bg p-3"
                      aria-labelledby="defaultDropdown"
                    >
                      <div>
                        <div className="currency_search_list overflow-y-auto">
                          <ul className="m-0  d-flex flex-column gap-2 p-0 mt-3">
                            {selectedTransactionType?.value === "deposit"
                              ? transectionDepositeCase.map((item) => {
                                  return (
                                    <li
                                      onClick={() =>
                                        setSelectedTransactionStatus(item)
                                      }
                                      key={item.value}
                                      className={`cursor-pointer d-flex justify-content-between align-items-center dropList ${
                                        item.value ===
                                        selectedTraasnactionStatus?.value
                                          ? "selected"
                                          : ""
                                      }`}
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        {item.type}
                                      </div>
                                      <div
                                        className={`radio-btn  ${
                                          item.value ===
                                          selectedTraasnactionStatus?.value
                                            ? "radio-checked"
                                            : ""
                                        } `}
                                      >
                                        <div className="radio-outer">
                                          <div className="radio-inner"></div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })
                              : selectedTransactionType?.value === "withdraw"
                              ? transectionWithdrawCase.map((item) => {
                                  return (
                                    <li
                                      onClick={() =>
                                        setSelectedTransactionStatus(item)
                                      }
                                      key={item.value}
                                      className={`cursor-pointer d-flex justify-content-between align-items-center dropList ${
                                        item.value ===
                                        selectedTraasnactionStatus?.value
                                          ? "selected"
                                          : ""
                                      }`}
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        {item.type}
                                      </div>
                                      <div
                                        className={`radio-btn  ${
                                          item.value ===
                                          selectedTraasnactionStatus?.value
                                            ? "radio-checked"
                                            : ""
                                        } `}
                                      >
                                        <div className="radio-outer">
                                          <div className="radio-inner"></div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })
                              : ""}
                          </ul>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="transaction_table mt-5">
            <div className="mb-2">
              <Link
                data-bs-toggle="modal"
                data-bs-target="#transactionModal"
                className="text-white"
              >
                <span className="pe-2">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    version="1.1"
                    viewBox="0 0 16 16"
                    height="20px"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8 1.5c-1.736 0-3.369 0.676-4.596 1.904s-1.904 2.86-1.904 4.596c0 1.736 0.676 3.369 1.904 4.596s2.86 1.904 4.596 1.904c1.736 0 3.369-0.676 4.596-1.904s1.904-2.86 1.904-4.596c0-1.736-0.676-3.369-1.904-4.596s-2.86-1.904-4.596-1.904zM8 0v0c4.418 0 8 3.582 8 8s-3.582 8-8 8c-4.418 0-8-3.582-8-8s3.582-8 8-8zM7 11h2v2h-2zM7 3h2v6h-2z"></path>
                  </svg>
                </span>
                Fiat Deposit issues or Disputes
              </Link>
            </div>
            <div className="table-responsive w-100 table_sec">
              <table className="table align-middle w-100">
                <thead>
                  <tr>
                    <th scope="col">Amount</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Created Time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Transaction Details</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={6} className="data_not_found">
                        <div className="d-flex  justify-content-center align-items-center py-5 ">
                          <Spinner />
                        </div>
                      </td>
                    </tr>
                  ) : transactionList.length ? (
                    transactionList.map((transaction) => (
                      <tr key={transaction._id}>
                        <td>{transaction.amount}</td>
                        <td>{transaction.coin}</td>
                        <td>
                          {new Date(transaction.createdAt).toLocaleTimeString(
                            "en-US",
                            { hour12: false }
                          )}
                        </td>
                        <td>{transaction.status}</td>
                        <td>{transaction.transaction_id}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="data_not_found">
                        <div className="d-flex flex-column gap-3 py-5">
                          <div className="empty_img">
                            <img
                              src={fileEmpty}
                              alt=""
                              style={{ height: "60px" }}
                              className="img-fluid"
                            />
                          </div>
                          <div className="text-center">
                            <div>No info yet</div>
                            <div>Invite friends to join you now!</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {loading || !transactionList.length ? (
              ""
            ) : (
              <div className="nextPrvButtonDiv">
                <button
                  onClick={() => {
                    if (page === 1) return;
                    setPage((prv) => prv - 1);
                  }}
                  className={`  ${page === 1 ? "disable" : ""} `}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="18px"
                    width="18px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </button>
                <button
                  className={`  ${page === totalPages ? "disable" : ""} `}
                  onClick={() => {
                    if (page === totalPages) return;
                    setPage((prv) => prv + 1);
                  }}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="18px"
                    width="18px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <DisputeWalletModal id="transactionModal" />
    </>
  );
};

export default TransactionTab;
