import React, { useEffect, useRef, useState } from "react";
import NoData from "./../../assets/images/Isolation_Mode.png";
import menuIcongray from "./../../assets/images/browsegray.png";
import menuIconActive from "./../../assets/images/browse.png";
import menuToggle from "./../../assets/images/menuToggle.svg";
import sportsIcongray from "./../../assets/images/sportsgray.png";
import sportsIconActive from "./../../assets/images/sports.png";
import casinoIcongray from "./../../assets/images/casinogray.png";
import casinoIconActive from "./../../assets/images/casino.png";
import futuresIcongray from "./../../assets/images/futuresgray.png";
import futuresIconActive from "./../../assets/images/futures.png";
import pokerIcongray from "./../../assets/images/pokergray.png";
import pokerIconActive from "./../../assets/images/poker.png";
import giftgray from "./../../assets/images/giftnotification.png";
import searchgray from "./../../assets/images/search.png";
import user from "./../../assets/images/userProfile.png";
import plus from "./../../assets/images/plusDrop.png";
import Affiliate from "./../../assets/images/affilate.png";
import Deposits from "./../../assets/images/deposit.png";
import Withdrawals from "./../../assets/images/withdrawls.png";
import Settings from "./../../assets/images/settingDrop.png";
import Sign from "./../../assets/images/signOut.png";
import eth from "./../../assets/images/blue.png";
import GreenLock from "./../../assets/images/green-lock.png";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import AuthModal from "../AuthModal";
import { useAuthModal } from "../../hooks/useAuthModal";
import {
  ACCOUNT_TABS,
  AUTH_MODAL_TABS,
  CLICK_ID,
  NEW_CLICK_ID,
  DEFAULT_CURRENCY,
  GAME_OPTION_TABS,
  ROUTE_CONST,
  SELECTED_CURRENCY,
  TABS_OPTIONS,
  WALLET_MODAL_TABS,
  USER_LANG,
  SPIN_MODAL_OPTIONS,
  SPIN_TOKEN,
} from "../../constants";
import Dropdown from "react-bootstrap/Dropdown";
import LogoutModal from "../modals/logoutModal/LogoutModal";
import { useAuth } from "../../hooks/useAuth";
import { useLogoutModal } from "../../hooks/useLogout";
import ReferralModal from "../ModalSection/ReferralModal";
import { fetchUserDetails } from "../../services/fetchUserDetails";
import { useDispatch, useSelector } from "react-redux";
import { reducerConst } from "../../constants/storeConstants";
import UserBalanceComp from "./components/UserBalanceComp";
import { useTranslation } from "react-i18next";
import { useClaimBonus } from "../../hooks/useClaimBonus";
import { useResultModal } from "../../hooks/useResult";
import { useWalletModal } from "../../hooks/useWallet";
import { useNotification } from "../../hooks/useNotification";
import { getAPIAuth, postAPIAuth } from "../../services/apiInstance";
import {
  getFavCryptoAction,
  getFavFiatAction,
  getUserBonusDetails,
  userBalanceAction,
} from "../../store/action";
import { convertToEightDigitsAfterDecimal } from "../../utils/convertToEightDigitsAfterDecimal";
import { Spinner } from "react-bootstrap";
import FiatModal from "./components/FiatModal";
import AddCurrencyModal from "./components/AddCurrencyModal";
import { useAccount } from "../../hooks/useAccount";
import Notification from "../NotificationOffcanvas/Notification";
import MessageOffcanvas from "../MessageOffcanvas";
import { socket } from "../../services/socket";
import { useChat } from "../../hooks/useChat";
import ResultModal from "../resultModal/ResultModal";
import WalletModal from "../WalletOptions/WalletModal";
import plusIcon from "./../../assets/images/plus.png";
import UnAuthCurrencyModal from "./components/UnAuthCurrencyModal";
import { useGames } from "../../hooks/useGame";
import DepositeMessage from "./components/DepositeMessage";
import FreeSpinMsgModal from "../freeSpinMsgModal/FreeSpinMsgModal";
import menuIcon from "./../../assets/images/menuIcon.png";
import Avatar from "./../../assets/images/avatar.png";
import NotificationBell from "./../../assets/images/Vector.png";
import Country from "./../../assets/images/country.png";
import CasinoLogo from "./../../assets/images/LOGO.svg";
import CasinoIcon from "./../../assets/images/casinoicon.svg";
import Sportsicon from "./../../assets/images/Sportsicon.svg";
import Comment from "./../../assets/images/comment.svg";
import HeaderGiftIcon from "./../../assets/images/HeaderGiftIcon.svg";
import MobileLogo from "./../../assets/images/MobileLogo.svg";
import searchIcon from "./../../assets/images/searchIcon.svg";
import menuSearch from "./../../assets/images/menuSearch.svg";
import menuChat from "./../../assets/images/menuChat.svg";

import userDropProfile from "./../../assets/images/profile.svg";
import userWallet from "./../../assets/images/wallet.svg";
import userWithdraw from "./../../assets/images/withdraw.svg";
import userTransactions from "./../../assets/images/transactions.svg";
import userAffiliate from "./../../assets/images/affiliate-icon.svg";
import userSetting from "./../../assets/images/settings.svg";
import userLogout from "./../../assets/images/logout.svg";
import LoginSuccessModal from "../LoginSuccessModal/LoginSuccessModal";
import c1 from "./../../assets/images/c1.png";
import france from "./../../assets/images/france.png";
import i18next from "i18next";
import { errorToaster, succesToaster } from "../../utils/toaster";
import SpinWheel from "../ModalSection/SpinWheel";
import SpinWheelModal from "../SpinWheelModal";
import SpinWheelWinnerModal from "../SpinWheelModal/component/SpinWheelWinnerModal";
import { useSpin } from "../../hooks/useSpin";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import DBLRackBack from "../ModalSection/Dblrackback";
import DBLBonusHistory from "../ModalSection/DblBounseHistory";
import UserAllBlanceComp from "./components/UserAllBlanceComp";

const option = {
  CURRENCY: "CURRENCY",
  mNFT: "mNFT",
};

const Header = ({ isSports }) => {
  const { accountTab, setAccountTab } = useAccount();
  const { setIsLogoutOpen } = useLogoutModal();
  const { setAuthModalObject, authModalObject } = useAuthModal();
  const { token } = useAuth();
  const [isUnAuthCurrency, setIsUnAuthCurrency] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const [currency, setCurrency] = useState();
  const [selectedTab, setSelectedTab] = useState("");
  const [language, setLanguage] = useState([]);
  const [languageSelectedTab, setLanguageSelectedTab] = useState();
  const dispatch = useDispatch();
  const { setIsNotificationOpen } = useNotification();
  const { walletModalObject, setWalletModalObject } = useWalletModal();
  const { t } = useTranslation();
  const textLabels = t("UnAuthCurrencyModal", { returnObjects: true });
  const desktopHeader = t("header", { returnObjects: true }); //string has to be same as language json key
  const desktopaccountPageLabels = t("accountPageLabels", {
    returnObjects: true,
  }); //string has to be same as language json key
  const [showLanguage, setShowLanguage] = useState(false);
  const { setToken } = useAuth();
  const { resultObject, setResultObject } = useResultModal();
  const userBalance = useSelector((state) => state.userBalance);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [showUsdtDetailModal, setShowUsdtDetailModal] = useState(false);
  const [showUsdtBonusModal, setShowUsdtBonusModal] = useState(false);
  const [showBonusHistoryModal, setShowBonusHistoryModal] = useState(false);
  const [new_click_id, setNew_Click_id] = useState(
    searchParams.get("clickid") ?? localStorage.getItem(NEW_CLICK_ID)
  );
  const [click_id, setClick_id] = useState(
    searchParams.get("click_id") ?? sessionStorage.getItem(CLICK_ID)
  );
  const interValRef = useRef(null);
  const [notifyStatus, setNotifyStatus] = useState();
  const currencyExchange = useSelector((state) => state.currencyExchangeData);
  const [query, setQuery] = useSearchParams();
  const [key, setKey] = useState("Currency");
  const [showFiatModal, setShowFiatModal] = useState(false);
  // const dispatch = useDispatch()
  // const userBalance = useSelector(state => state.userBalance)
  const [showFavoriteModal, setShowFavoriteModal] = useState(false);
  const [favCrypto, setFavCrypto] = useState([]);
  const [favFiat, setFavFiat] = useState([]);
  const favFiatDetails = useSelector((state) => state.favFiat);
  const favCryptoDetails = useSelector((state) => state.favCrypto);
  const [isChecked, setIsChecked] = useState(false);
  const [userCurrency, setUserCurrency] = useState();
  const [currencyLoader, setCurrencyLoader] = useState({
    index: "",
    status: false,
    type: "",
  });
  const [isHidden, setIsHidden] = useState(false);
  const [showLocked, setShowLocked] = useState(false);
  const [selectedDrop, setSelectedDrop] = useState(option.CURRENCY);
  const [currencySearch, setCurrencySearch] = useState("");
  const intervalRef = useRef();
  const arrPath = location.pathname.split("/");
  const myDivRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { setClaimBonusObject } = useClaimBonus();
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  const { setIsChatOpen } = useChat();
  const { setGameObject } = useGames();
  const { spinObject, setSpinObject } = useSpin();
  const isSpinSet = localStorage.getItem(SPIN_TOKEN);

  const [showDepositMsg, setShowDepositMsg] = useState(false);
  const [depositMsgData, setDepositMsgData] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("english");

  const handleLanguageChange = (event, language) => {
    setSelectedLanguage(language);
  };

  const handleLogin = () => {
    setAuthModalObject((prev) => ({
      isAuthOpen: true,
      selectedTab: AUTH_MODAL_TABS.LOG_IN,
    }));
    setQuery({ action: "login" });
  };

  const handleRegister = () => {
    setAuthModalObject((prev) => ({
      isAuthOpen: true,
      selectedTab: AUTH_MODAL_TABS.SIGN_UP,
    }));
    setQuery({ action: "register" });
  };

  const handleSidebar = () => {
    const body = document.body;
    if (body.classList.contains("showSidebar")) {
      body.classList.remove("showSidebar");
    } else {
      body.classList.add("showSidebar");
    }
  };

  // close sidebar on route change
  useEffect(() => {
    const body = document.body;
    if (body.classList.contains("showSidebar")) {
      body.classList.remove("showSidebar");
    }
  }, [location?.pathname, query.get("q")]);

  useEffect(() => {
    if (token) {
      const getData = async () => {
        const res = await fetchUserDetails(token);
        if (res?.response?.data?.msg === "Invalid token") {
        } else {
          dispatch({
            type: reducerConst.USER_DETAILS,
            payload: res?.data?.data ? res.data.data : {},
          });
        }
      };
      getData();
    }
  }, [token, isChecked, userDetails?.usercurrency]);

  // for checking if dropdown has show class or not
  // useEffect(() => {
  //   let observer;
  //   if (token) {
  //     observer = new MutationObserver((mutationsList) => {
  //       for (let mutation of mutationsList) {
  //         if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
  //           const hasClass = myDivRef?.current?.classList?.contains('show');
  //           setDropdownOpen(hasClass)
  //         }
  //       }
  //     });

  //     observer?.observe(myDivRef.current, { attributes: true });
  //   }

  //   return () => {
  //     if (token) {

  //       observer?.disconnect();
  //     }
  //   };
  // }, [token]);

  useEffect(() => {
    if (Object.keys(userDetails).length !== 0) {
      setIsChecked(userDetails?.fiatStatus);
    }
  }, [userDetails.id]);

  useEffect(() => {
    if (!token) {
      if (query.get("action") == "register") {
        setAuthModalObject((pre) => ({
          ...pre,
          isAuthOpen: true,
          selectedTab: AUTH_MODAL_TABS.SIGN_UP,
        }));
      } else if (query.get("action") == "login") {
        setAuthModalObject((pre) => ({
          ...pre,
          isAuthOpen: true,
          selectedTab: AUTH_MODAL_TABS.LOG_IN,
        }));
      } else if (query.get("action") === "forgotPassword") {
        setAuthModalObject((pre) => ({
          ...pre,
          isAuthOpen: true,
          selectedTab: AUTH_MODAL_TABS.LOG_IN,
        }));
      }
    } else {
      if (query.get("action") == "wallet") {
        setWalletModalObject((pre) => ({
          ...pre,
          isWalletOpen: true,
          selectedTab: WALLET_MODAL_TABS.DEPOSIT,
        }));
      }
    }
  }, [query.get("action"), token]);

  useEffect(() => {
    const handleMessage = (event) => {
      console.log("event", event);
      if (event.data === "navigate-home") {
        navigate("/");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [location]);

  useEffect(() => {
    setLanguage(localStorage.getItem(USER_LANG));
  }, []);

  //   useEffect(() => {
  //       if (query.get("action") == 'wallet' && !walletModalObject.isWalletOpen) {
  //         setWalletModalObject(pre => ({ selectedTab: '' }))
  //         setQuery({action:""})
  //       }

  //   }, [query.get("action"), !walletModalObject.isWalletOpen])

  //   useEffect(() => {
  //     if (query.get("action") == 'register'&&!authModalObject.isAuthOpen ||query.get("action") == 'login' && !authModalObject.isAuthOpen ||query.get('action') === 'forgotPassword' && !authModalObject.isAuthOpen) {
  //       setAuthModalObject(pre => ({ selectedTab: '' }))
  //       setQuery({action:""})
  //     }

  // }, [!authModalObject.isAuthOpen])

  ///----->>>notification------------>>>
  const checkStatus = async () => {
    try {
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws"
      const res = await getAPIAuth("user/checkReadStatus", token);
      if (res?.data?.success) {
        setNotifyStatus(res?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (token) {
      if (interValRef.current) {
        clearInterval(interValRef.current);
      }
      interValRef.current = setInterval(() => {
        checkStatus();
      }, 20000);
    }

    return () => {
      clearInterval(interValRef.current);
    };
  }, [token]);

  // used in authModal
  // removed in logout modal
  useEffect(() => {
    localStorage.setItem(NEW_CLICK_ID, new_click_id);
  }, [new_click_id]);

  useEffect(() => {
    sessionStorage.setItem(CLICK_ID, click_id);
  }, [click_id]);

  const getUserBal = async () => {
    try {
      const res = await getAPIAuth(
        `user/user-balance?userId=${userDetails?.id}`,
        token
      );
      if (res?.data?.success) {
        // setUserBal(res.data.data)
        dispatch(userBalanceAction(res.data.data));
      }
    } catch (error) {}
  };

  // get cashback data
  const getCashbackData = async () => {
    try {
      const res = await getAPIAuth(`user/get-userbonus-stats`, token);
      if (res?.data?.success) {
        dispatch(
          getUserBonusDetails({
            bonusData: res?.data?.data?.[0],
            lockedStatus: res.data?.lockStatus,
            freeSpinPopupStatus: res?.data?.freeSpinPopupStatus,
          })
        );
        // setLockStatus(res.data?.lockStatus)
        // setBonusData(res?.data?.data?.[0])
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (token) {
      getCashbackData();
    }
  }, [token]);

  useEffect(() => {
    let timer;
    if (token && location.search.includes("freeSpinGame")) {
      timer = setInterval(() => {
        getCashbackData();
      }, 2000);
    }
    return () => {
      if (token && location.search.includes("freeSpinGame")) {
        clearInterval(timer);
      }
    };
  }, [token, location]);

  useEffect(() => {
    {
      /* <script src='https://static-cdn.trackier.com/js/trackier-web-sdk.js'></script>
  <script>TrackierWebSDK.init()</script> */
    }
    // const script = document.createElement('script');
    // script.src = 'https://static-cdn.trackier.com/js/trackier-web-sdk.js';
    // script.async = true;
    // let trackingToken = window?.TrackierWebSDK.getTrackingToken()
    let trackingToken = window?.TrackierWebSDK?.getTrackingToken();
    if (trackingToken) {
      setClick_id(trackingToken);
    }
  }, []);

  const getFav = async () => {
    try {
      const res = await getAPIAuth(
        `crypto/get-fav-currency-crypto?userId=${userDetails?.id}`,
        token
      );
      if (res?.data?.success) {
        setFavFiat(res?.data?.fiat);
        dispatch(getFavFiatAction(res?.data?.fiat));
        setFavCrypto(res?.data?.crypto);
        dispatch(getFavCryptoAction(res?.data?.crypto));
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (userDetails?.id) {
      getUserBal();
      getFav();
      // userCryptoDetails();
    }
    // }, [userDetails.id, favFiatDetails, favCryptoDetails, showFavoriteModal, isChecked])
  }, [userDetails.id, showFavoriteModal, isChecked]);

  // to show updated user balance
  useEffect(() => {
    if (userDetails?.id && token) {
      // polling
      if (dropdownOpen) {
        //to call the api only when dropdown is open

        if (intervalRef.current) {
          //if previously any interval is running ,cancel it
          clearInterval(intervalRef.current);
        }
        // start a new interval
        intervalRef.current = setInterval(() => {
          getUserBal();
          getFav();
        }, 3000);
      }
    }
    return () => clearInterval(intervalRef.current); // clean up , when component unmounts
  }, [token, userDetails?.id, dropdownOpen]);

  useEffect(() => {
    const item = JSON.parse(localStorage.getItem(SELECTED_CURRENCY));
    if (!!userDetails?.usercurrency) {
      setCurrency(userDetails.usercurrency);
    } else if (item?.id) {
      setCurrency(item?.code);
    } else {
      setCurrency(DEFAULT_CURRENCY.code);
    }
  }, [
    JSON.parse(localStorage.getItem(SELECTED_CURRENCY))?._id,
    userDetails?.id,
    token,
    userDetails?.usercurrency,
  ]);

  const handleChange = async (item) => {
    try {
      const res = await getAPIAuth(
        `user/setCryptoForUser?currency=${item.code}&type=${item.type}&userId=${userDetails.id}`,
        token
      );
      if (res?.data?.success) {
        setUserCurrency(res.data.data);
        // get user updated wallet info--->>>
        const getUserBal = async () => {
          try {
            const res = await getAPIAuth(
              `user/user-balance?userId=${userDetails?.id}`,
              token
            );
            if (res?.data?.success) {
              // setUserBal(res.data.data)
              dispatch(userBalanceAction(res.data.data));
            }
          } catch (error) {}
        };
        getUserBal();
        const dropdownMenus =
          document.getElementsByClassName("dropdown-menu show");
        const dropdownMenusArray = Array.from(dropdownMenus);
        dropdownMenusArray.map((menu) => {
          menu.classList.remove("show");
        });
      }
    } catch (error) {
    } finally {
      setCurrencyLoader((pre) => ({
        ...pre,
        index: "",
        status: false,
        type: "",
      }));
    }
  };
  const searchHandler = (e) => {
    if (e !== "") {
      const res = getAPIAuth(
        `crypto/get-fav-currency-crypto?userId=${
          userDetails.id
        }&search=${e.trim(" ")}`
      );

      if (res?.data?.success) {
        setFavFiat(res?.data?.fiat);
        setFavCrypto(res?.data?.crypto);
      }
    }
  };

  useEffect(() => {
    if (currencySearch) {
      const filterFiat = favFiatDetails.filter(
        (item) =>
          item.code.toLowerCase().includes(currencySearch.toLowerCase()) ||
          item.name.toLowerCase().includes(currencySearch.toLowerCase())
      );
      setFavFiat(filterFiat);
      const filterCrypto = favCryptoDetails.filter(
        (item) =>
          item.code.toLowerCase().includes(currencySearch.toLowerCase()) ||
          item.name.toLowerCase().includes(currencySearch.toLowerCase())
      );
      setFavCrypto(filterCrypto);
    } else {
      setFavFiat(favFiatDetails);
      setFavCrypto(favCryptoDetails);
    }
  }, [currencySearch]);

  const handleViewFiat = async (value) => {
    try {
      const res = await getAPIAuth(
        `user/setFiatForUser?fiatStatus=${value}&userId=${userDetails.id}`,
        token
      );
      if (res?.data?.success) {
        setIsChecked(res.data.data.fiatStatus);
        if (res.data.data.fiatStatus === true) {
          setShowFiatModal(true);
        } else if (res.data.data.fiatStatus === false) {
          setShowFiatModal(false);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (userDetails?.id) {
      try {
        socket.on(`deposit-${userDetails?.id}`, (data) => {
          if (data?.amount) {
            setShowDepositMsg(true);
            setDepositMsgData(data);
          }
        });
      } catch (error) {}
    }
  }, [userDetails?.id]);

  const handleButtonClick = () => {
    document.body.classList.toggle("sidebarToggle");
  };

  const queryParams = new URLSearchParams(location.search);
  const hasQueryParams = Array.from(queryParams.keys()).length > 0;

  const languageHandler = async (item) => {
    if (token) {
      try {
        const body = {
          language: item?.symbol,
        };
        const res = await postAPIAuth(
          `user/change-user-lang?userId=${userDetails?.id}`,
          body,
          token
        );
        if (res.data.success) {
          // setLanguage(item.symbol);
          if (!language) {
            return;
          }
          i18next
            .changeLanguage(item.symbol)
            .catch((err) => console.error("Failed to change language", err));
          // i18next.changeLanguage(language)
          // caching language user has chosen
          localStorage.setItem(USER_LANG, item?.symbol);
          succesToaster(`${textLabels.Language_successfully}`);
          setLanguage(item?.symbol);
        } else {
          errorToaster(`${textLabels.Language_Error}`);
        }
      } catch (error) {
        errorToaster(`${textLabels.Language_Error}`);
        // errorToaster("Error while changing language")
      }
    } else {
      localStorage.setItem(USER_LANG, item?.symbol);
      i18next.changeLanguage(item.symbol);
      setLanguage(item?.symbol);
      // succesToaster("Language changed successfully ")
      succesToaster(`${textLabels.Language_successfully}`);
    }
  };
  useEffect(() => {
    const time = setTimeout(() => {
      if (token) {
        setSpinObject((prev) => ({
          ...prev,
          isSpinOpen: false,
        }));
        return;
      }
      if (!isSpinSet) {
        setSpinObject({
          isSpinOpen: true,
          selectedModal: SPIN_MODAL_OPTIONS.SPIN_MODAL,
        });
      }
    }, 100);
    return () => {
      clearTimeout(time);
    };
  }, []);

  return (
    <>
      <div
        className={`header ${isSports ? "sportsHeader" : ""}`}
        onMouseLeave={() => setShowLocked(false)}
      >
        <div className="upperHeader">
          <div className="row align-items-center h-100 mx-0">
            {location.pathname === ROUTE_CONST.SPORTS_PAGE ||
            (location.pathname === ROUTE_CONST.SPORTS_PAGE &&
              hasQueryParams) ? null : (
              <div
                onClick={handleButtonClick}
                className="col-auto menu-icon d-lg-block d-none"
              >
                <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                  <div class="iconHeader">
                    <img src={menuIcon} alt="" />
                  </div>
                </div>
              </div>
            )}
            <div className="col-auto logoLinkCol d-lg-block d-none">
              <Link to={"/"} className="logoLink text-decoration-none d-block">
                <img
                  src={CasinoLogo}
                  alt="logo"
                  className="h-100 w-100 object-fit-contain"
                />
              </Link>
            </div>
            <div className="col-auto logoLinkCol d-lg-none d-block ps-0">
              <Link to={"/"} className="logoLink text-decoration-none d-block">
                <img
                  src={CasinoLogo}
                  alt="logo"
                  className="h-100 w-100 object-fit-contain"
                />
              </Link>
            </div>

            {token ? (
              ""
            ) : (
              <div className={`col-auto d-lg-none d-block px-1 px-md-2`}>
                <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                  <div className="iconHeader">
                    <img src={HeaderGiftIcon} alt="" />
                  </div>
                </div>
              </div>
            )}

            {token ? (
              <div className="col-auto d-lg-none d-block px-1 px-md-2">
                <div className="userBalance d-flex align-items-center justify-content-between">
                  <span
                    className="plusBtn d-flex justify-content-center align-items-center"
                    onClick={() => {
                      setWalletModalObject((prev) => ({
                        isWalletOpen: true,
                        selectedTab: WALLET_MODAL_TABS.DEPOSIT,
                      }));
                      setQuery({ action: "wallet" });
                    }}
                  >
                    <img
                      src={plusIcon}
                      alt="plus icon"
                      className="object-fit-contain"
                    />
                  </span>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="headerFiatDrop"
                    >
                      <UserBalanceComp setShowLocked={setShowLocked} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="innerUsdtBtn userBalInnerDrop">
                      <div className="row mx-0">
                        <div className="col-12 px-0">
                          <div className="row mx-0">
                            <div className="col px-0">
                              <div className="searchInp">
                                <img src="./assets/img/searchGray.png" alt="" />
                                <input
                                  type="text"
                                  className="form-control shadow-none"
                                  placeholder="Search"
                                  value={currencySearch}
                                  // onChange={(e) => searchHandler(e.target.value)}
                                  onChange={(e) =>
                                    setCurrencySearch(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-auto ps-2 pe-0">
                              <div
                                className="plusBtn grayBtn"
                                onClick={() => setShowFavoriteModal(true)}
                              >
                                +
                                {/* <AddCurrency showFavoriteModal={showFavoriteModal} /> */}
                              </div>
                            </div>
                            <div className="col-12 px-0">
                              <div className="dropTabs">
                                <div
                                  className={`tab ${
                                    selectedDrop === option.CURRENCY
                                      ? "active"
                                      : ""
                                  } `}
                                  onClick={() =>
                                    setSelectedDrop(option.CURRENCY)
                                  }
                                >
                                  Currency
                                </div>
                                <div
                                  className={`tab ${
                                    selectedDrop === option.mNFT ? "active" : ""
                                  } `}
                                  onClick={() => setSelectedDrop(option.mNFT)}
                                >
                                  mNFT
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {selectedDrop === option.CURRENCY ? (
                          <>
                            <div className="innerScrollDiv">
                              <div className="col-12 px-0">
                                {favFiat.length > 0 ? (
                                  <>
                                    {isHidden ? (
                                      ""
                                    ) : (
                                      <div className="dropTitle mb-1">Fiat</div>
                                    )}

                                    <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                      {favFiat?.map((item, index) => (
                                        <Dropdown.Item
                                          as="button"
                                          key={index}
                                          className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${
                                            isHidden &&
                                            parseFloat(item.totalBalance) ===
                                              0 &&
                                            parseFloat(item.totalBonus) === 0
                                              ? "d-none"
                                              : ""
                                          }  ${
                                            item.code ===
                                            userBalance?.setCurrencyData?.code
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setCurrencyLoader((pre) => ({
                                              ...pre,
                                              index: index,
                                              status: true,
                                              type: "fiat",
                                            }));
                                            handleChange(item);
                                          }}
                                        >
                                        <div>
                                          <div className="d-flex align-items-center">
                                              {currencyLoader.index == index &&
                                              currencyLoader.status &&
                                              currencyLoader.type == "fiat" ? (
                                                <>
                                                  <Spinner size="sm" />
                                                </>
                                              ) : (
                                                <>
                                                  <img
                                                    className="rounded"
                                                    src={item.icon}
                                                    alt=""
                                                  />
                                                </>
                                              )}
                                              {item.code}
                                          </div>
                                          
                                        </div>
                                          
                                          <div>
                                            {/* {userDetails.fiatStatus ? item.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                            {userDetails.fiatStatus
                                              ? userDetails?.currency?.symbol
                                              : ""}{" "}
                                            {item?.totalBalance
                                              ? convertToEightDigitsAfterDecimal(
                                                  item?.totalBalance
                                                )
                                              : "0.00"}
                                            {userDetails.fiatStatus ? (
                                              <span className="smallVal">
                                                {" "}
                                                {item.wallet?.totalBalance
                                                  ? convertToEightDigitsAfterDecimal(
                                                      item.wallet?.totalBalance
                                                    )
                                                  : "0.00"}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            
                                          </div>
                                        </Dropdown.Item>
                                      ))}
                                    </ul>

                                  </>
                                ) : null}
                              </div>
                              <div className="col-12 px-0">
                                {favCrypto.length > 0 ? (
                                  <>
                                    {/* <div className="dropTitle mb-1">Crypto</div> */}
                                    {isHidden ? (
                                      ""
                                    ) : (
                                      <div className="dropTitle mb-1">
                                        Crypto
                                      </div>
                                    )}

                                    <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                      {/*     */}
                                      {favCrypto?.map((item, index) => (
                                        <Dropdown.Item
                                          as="button"
                                          key={index}
                                          className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${
                                            isHidden &&
                                            parseFloat(item.totalBalance) ===
                                              0 &&
                                            parseFloat(item.totalBonus) === 0
                                              ? "d-none"
                                              : ""
                                          }  ${
                                            item.code ===
                                            userBalance?.setCurrencyData?.code
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            // setCurrencyLoader(true)
                                            setCurrencyLoader((pre) => ({
                                              ...pre,
                                              index: index,
                                              status: false,
                                              type: "crypto",
                                            }));

                                            handleChange(item);
                                          }}
                                        >
                                          <div className="d-flex align-items-center">
                                            {currencyLoader.index == index &&
                                            currencyLoader.status &&
                                            currencyLoader.type == "crypto" ? (
                                              <>
                                                <Spinner size="sm" />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  className="rounded"
                                                  src={item.icon}
                                                  alt=""
                                                />
                                              </>
                                            )}
                                            {item.code}
                                            {/* <img className="infoIcon"
                                              src="assets/img/header/exclamationYellow.png" alt=""
                                              onClick={() => setShowUsdtDetailModal(true)} /> */}
                                          </div>
                                          <div className="text-end">
                                            {/* {userDetails.fiatStatus ? item.currency.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                            {userDetails.fiatStatus
                                              ? userDetails?.currency?.symbol
                                              : ""}{" "}
                                            {item?.totalBalance
                                              ? convertToEightDigitsAfterDecimal(
                                                  item?.totalBalance
                                                )
                                              : "0.00"}
                                            {userDetails.fiatStatus ? (
                                              <span className="smallVal">
                                                {" "}
                                                {item.wallet?.totalBalance
                                                  ? convertToEightDigitsAfterDecimal(
                                                      item.wallet?.totalBalance
                                                    )
                                                  : "0.00"}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            {/* <div className="usdtValue2"
                                              onClick={() => setShowUsdtBonusModal(true)}
                                          >
                                              <img
                                                  src="assets/img/header/lockYellow.png" alt="" />
                                              $ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
                                          </div> */}
                                          </div>
                                        </Dropdown.Item>
                                      ))}
                                      {/* {
                                  currencyExchange?.length ? 
                                      currencyExchange?.map((item)=> (
                                        <>
                                          <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                              <div className="d-flex align-items-center">
                                                  <img className="rounded" src={item?.icon} alt="" />
                                                  {item?.name}
                                              </div>
                                              <div>
                                                  {Number(item?.usdAmount)?.toFixed(2)}
                                              </div>
                                          </li>
                                          <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                              <div className="d-flex align-items-center">
                                                  <img className="rounded" src={item?.icon} alt="" />
                                                  {item?.name}
                                              </div>
                                              <div>
                                                  {Number(item?.usdAmount)?.toFixed(2)}
                                              </div>
                                          </li>
                                          <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                              <div className="d-flex align-items-center">
                                                  <img className="rounded" src={item?.icon} alt="" />
                                                  {item?.name}
                                              </div>
                                              <div>
                                                  {Number(item?.usdAmount)?.toFixed(2)}
                                              </div>
                                          </li>
                                          <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                              <div className="d-flex align-items-center">
                                                  <img className="rounded" src={item?.icon} alt="" />
                                                  {item?.name}
                                              </div>
                                              <div>
                                                  {Number(item?.usdAmount)?.toFixed(2)}
                                              </div>
                                          </li>
                                        </>  
                                      ))
                                  : ''
                              } */}
                                    </ul>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="innerScrollDiv d-flex justify-content-center align-items-center flex-column"
                              style={{ gap: "20px" }}
                            >
                              {/* <div>
    <img
      src={NoData}
      alt=""
      style={{
        width: "100px",
      }}
    />
    <div className="dropTitle mb-1 text-center mt-3">
      No Data Available
    </div>
  </div> */}
                              <div style={{ position: "relative" }}>
                                <span>
                                  <svg
                                    width="74"
                                    height="61"
                                    viewBox="0 0 74 61"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.14062 9.43109V3.16768C1.14062 1.97355 2.114 1 3.30794 1H32.993C34.1869 1 35.1606 1.97941 35.1606 3.16768V6.30008C35.1606 7.48366 36.1342 8.46775 37.3281 8.46775H70.6045C71.9239 8.46775 72.9998 9.54354 72.9998 10.8629V53.17C72.9998 56.4666 70.311 59.1556 67.0142 59.1556H64.9196M1.14062 9.43109V45.0887M1.14062 9.43109C1.14062 8.23783 2.11244 7.26431 3.3076 7.26431H27.3076C28.5006 7.26431 29.4744 8.24269 29.4744 9.43111V12.5639C29.4744 13.7495 30.4479 14.7326 31.6427 14.7326H64.9197C66.1131 14.7326 67.0884 15.7137 67.0884 16.8994V56.9886C67.0884 58.1741 66.1053 59.1556 64.9197 59.1556H32.3302"
                                      stroke="#6A798C"
                                      strokeWidth="1.87194"
                                      strokeMiterlimit="22.9256"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span
                                  style={{
                                    position: "absolute",
                                    bottom: "-6px",
                                    left: "0px",
                                  }}
                                >
                                  <svg
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M5.12993 6.10063C11.9698 -0.739272 23.0596 -0.739272 29.8995 6.10063C36.7394 12.9405 36.7394 24.0303 29.8995 30.8702C23.0596 37.7101 11.9698 37.7101 5.12993 30.8702C-1.70998 24.0303 -1.70998 12.9405 5.12993 6.10063ZM10.0545 7.71085L28.2893 25.9456C31.8345 20.838 31.3316 13.7715 26.7801 9.22007C22.2287 4.66859 15.1622 4.16567 10.0545 7.71085ZM24.9751 29.26L6.74015 11.025C3.19485 16.1326 3.69779 23.1992 8.24934 27.7508C12.8009 32.3024 19.8676 32.8053 24.9751 29.26Z"
                                      fill="#F8BA28"
                                    />
                                  </svg>
                                </span>
                              </div>
                              <h1
                                style={{ fontSize: "18px", color: "#B2B6C5" }}
                              >
                                Oops! There is no data yet!
                              </h1>
                            </div>
                          </>
                        )}

                        <div className="col-12">
                          <div className="row pt-2 pb-1 dropCheckBoxWrapper">
                            <div className="col-6 dropCheckBox ps-0">
                              <input
                                onChange={(e) =>
                                  handleViewFiat(e.target.checked)
                                }
                                type="checkbox"
                                id="fiat"
                                className="d-none"
                                checked={isChecked}
                              />
                              <label
                                htmlFor="fiat"
                                className="checkLabel"
                              ></label>
                              <label htmlFor="fiat" className="textLabel">
                                View in fiat
                              </label>
                            </div>
                            <div className="col-6 dropCheckBox pe-0 justify-content-end">
                              <input
                                type="checkbox"
                                id="small"
                                className="d-none"
                                onChange={(e) => setIsHidden(e.target.checked)}
                              />
                              <label
                                htmlFor="small"
                                className="checkLabel"
                              ></label>
                              <label htmlFor="small" className="textLabel">
                                Hide Small
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <span className='userBal userBal1'><img src={eth} alt="" />$ 100.00</span> */}
                  <div>
                    <i class="fa-solid fa-sort-down text-white  me-2" style={{ marginBottom:"12px" }}></i>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col px-0">
              <div className="row mx-lg-0">
                <div className="col-12 d-flex align-items-center justify-content-lg-between justify-content-end headerPaddingCol ps-0">
                  <div className="headerIcons d-none">
                    <div className="row" style={{ "--bs-gutter-x": "2rem" }}>
                      <div
                        className={`col-auto ${
                          location?.pathname === ROUTE_CONST.CASINO
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          setGameObject((prev) => ({
                            selectedTab: GAME_OPTION_TABS?.LOBBY,
                          }));
                          setQuery({ q: "lobby" });
                          navigate(
                            `${ROUTE_CONST.CASINO}?q=${encodeURIComponent(
                              "lobby"
                            )}`
                          );
                        }}
                      >
                        <div className={`icons`}>
                          <img
                            className="nonActiveImg d-flex"
                            src={casinoIcongray}
                            alt=""
                          />
                          <img
                            className="d-none activeImg"
                            src={casinoIconActive}
                            alt="menuIcon"
                          />
                        </div>
                        <div className="icontext">{desktopHeader.Casino}</div>
                      </div>
                      <div
                        className={`col-auto ${
                          location?.pathname === ROUTE_CONST.SPORTS_PAGE
                            ? "active"
                            : ""
                        }`}
                        onClick={() => navigate(ROUTE_CONST.SPORTS_PAGE)}
                      >
                        <div className="icons">
                          <img
                            className="nonActiveImg d-flex"
                            src={sportsIcongray}
                            alt=""
                          />
                          <img
                            className="d-none activeImg"
                            src={sportsIconActive}
                            alt="menuIcon"
                          />
                        </div>
                        <div className="icontext">{desktopHeader.Sports}</div>
                      </div>
                      <div
                        className={`col-auto ${
                          location?.pathname === ROUTE_CONST.POKER_PAGE
                            ? "active"
                            : ""
                        }`}
                        onClick={() => navigate(ROUTE_CONST.POKER_PAGE)}
                      >
                        <div className="icons">
                          <img
                            className="nonActiveImg d-flex"
                            src={pokerIcongray}
                            alt=""
                          />
                          <img
                            className="d-none activeImg"
                            src={pokerIconActive}
                            alt="menuIcon"
                          />
                        </div>
                        <div className="icontext">{desktopHeader.poker}</div>
                      </div>
                      <div
                        className="col-auto"
                        onClick={() => navigate(ROUTE_CONST.COMING_SOON)}
                      >
                        <div className="icons">
                          <img
                            className="nonActiveImg d-flex"
                            src={futuresIcongray}
                            alt=""
                          />
                          <img
                            className="d-none activeImg"
                            src={futuresIconActive}
                            alt="menuIcon"
                          />
                        </div>
                        <div className="icontext">{desktopHeader.futures}</div>
                      </div>
                    </div>
                  </div>
                  <div class="headerIcons d-flex align-items-center header-left-bar">
                    <div
                      onClick={() => navigate(ROUTE_CONST.CASINO + "?q=lobby")}
                      className="game-box d-lg-block d-none "
                    >
                      <div class="d-flex align-items-center justify-content-center gap-2">
                        <img src={CasinoIcon} alt="" />
                        <h6 class="text-white mb-0">Casino</h6>
                      </div>
                    </div>
                    <div
                      onClick={() => navigate(ROUTE_CONST.SPORTS_PAGE)}
                      className="game-box d-lg-block d-none"
                    >
                      <div class="d-flex align-items-center justify-content-center gap-2">
                        <img src={Sportsicon} alt="" />
                        <h6 class="text-white mb-0">Sports</h6>
                      </div>
                    </div>
                  </div>

                  {/* for desktop */}
                  <div className="row align-items-center d-lg-flex d-none header-right-bar">
                    {/* after login */}
                    {/* {
                      token && (

                        <div className="col-auto"onClick={() => navigate(ROUTE_CONST.BONUS)}>
                          <div className="iconHeader">
                            <img src={giftgray} alt="" />
                          </div>
                        </div>
                      )
                    } */}
                    <div
                      className={`col-auto ${token ? "" : "pe-0"}`}
                      onClick={() => {
                        setResultObject({
                          ...resultObject,
                          isResultOpen: true,
                        });
                      }}
                    >
                      <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                        <div className="iconHeader">
                          <img src={searchgray} alt="" />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-auto" onClick={() => setIsNotificationOpen(true)}>
                      <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                        <div className={`iconHeader iconHeaderN ${notifyStatus?.status ? "active" : ""}`}>
                          <img src={NotificationBell} alt="" />
                        </div>
                      </div>
                    </div> */}
                    {/* after login */}
                    {token && (
                      <>
                        <div className="col-auto position-relative">
                          <div className="col-auto">
                            <div className="userBalance d-flex align-items-center justify-content-between">
                              <span
                                className="plusBtn d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  setWalletModalObject((prev) => ({
                                    isWalletOpen: true,
                                    selectedTab: WALLET_MODAL_TABS.DEPOSIT,
                                  }));
                                  setQuery({ action: "wallet" });
                                }}
                              >
                                <img
                                  src={plusIcon}
                                  alt="plus icon"
                                  className="object-fit-contain"
                                />
                              </span>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                  className="headerFiatDrop"
                                >
                                  <UserBalanceComp
                                    setShowLocked={setShowLocked}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="innerUsdtBtn userBalInnerDrop">
                                  <div className="row mx-0">
                                    <div className="col-12 px-0">
                                      <div className="row mx-0">
                                        <div className="col px-0">
                                          <div className="searchInp">
                                            <img
                                              src="./assets/img/searchGray.png"
                                              alt=""
                                            />
                                            <input
                                              type="text"
                                              className="form-control shadow-none"
                                              placeholder="Search"
                                              value={currencySearch}
                                              // onChange={(e) => searchHandler(e.target.value)}
                                              onChange={(e) =>
                                                setCurrencySearch(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-auto ps-2 pe-0">
                                          <div
                                            className="plusBtn grayBtn"
                                            onClick={() =>
                                              setShowFavoriteModal(true)
                                            }
                                          >
                                            +
                                            {/* <AddCurrency showFavoriteModal={showFavoriteModal} /> */}
                                          </div>
                                        </div>
                                        <div className="col-12 px-0">
                                          <div className="dropTabs">
                                            <div
                                              className={`tab ${
                                                selectedDrop === option.CURRENCY
                                                  ? "active"
                                                  : ""
                                              } `}
                                              onClick={() =>
                                                setSelectedDrop(option.CURRENCY)
                                              }
                                            >
                                              Currency
                                            </div>
                                            <div
                                              className={`tab ${
                                                selectedDrop === option.mNFT
                                                  ? "active"
                                                  : ""
                                              } `}
                                              onClick={() =>
                                                setSelectedDrop(option.mNFT)
                                              }
                                            >
                                              mNFT
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {selectedDrop === option.CURRENCY ? (
                                      <>
                                        <div className="innerScrollDiv">
                                          <div className="col-12 px-0">
                                            {favFiat.length > 0 ? (
                                              <>
                                                {isHidden ? (
                                                  ""
                                                ) : (
                                                  <div className="dropTitle mb-1">
                                                    Fiat
                                                  </div>
                                                )}

                                                <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                                  {favFiat?.map(
                                                    (item, index) => (
                                                      <Dropdown.Item
                                                        as="button"
                                                        key={index}
                                                        className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${
                                                          isHidden &&
                                                          parseFloat(
                                                            item.totalBalance
                                                          ) === 0 &&
                                                          parseFloat(
                                                            item.totalBonus
                                                          ) === 0
                                                            ? "d-none"
                                                            : ""
                                                        }  ${
                                                          item.code ===
                                                          userBalance
                                                            ?.setCurrencyData
                                                            ?.code
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                        onClick={() => {
                                                          setCurrencyLoader(
                                                            (pre) => ({
                                                              ...pre,
                                                              index: index,
                                                              status: true,
                                                              type: "fiat",
                                                            })
                                                          );
                                                          handleChange(item);
                                                        }}
                                                      >
                                                      <div>

                                                     
                                                        <div className="d-flex align-items-center">
                                                          {currencyLoader.index ==
                                                            index &&
                                                          currencyLoader.status &&
                                                          currencyLoader.type ==
                                                            "fiat" ? (
                                                            <>
                                                              <Spinner size="sm" />
                                                            </>
                                                          ) : (
                                                            <>
                                                              <img
                                                                className="rounded"
                                                                src={item.icon}
                                                                alt=""
                                                              />
                                                            </>
                                                          )}
                                                          {item.code}
                                                        </div>
                                                          <div>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                              <img src={GreenLock} alt="" style={{height:"15px"}} />
                                                              <p className="fiatlockedBal mb-0">$ 355334</p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        
                                                        <div>
                                                          {/* {userDetails.fiatStatus ? item.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                                          {userDetails.fiatStatus
                                                            ? userDetails
                                                                ?.currency
                                                                ?.symbol
                                                            : ""}{" "}
                                                          {item?.totalBalance
                                                            ? convertToEightDigitsAfterDecimal(
                                                                item?.totalBalance
                                                              )
                                                            : "0.00"}
                                                          {userDetails.fiatStatus ? (
                                                            <span className="smallVal">
                                                              {" "}
                                                              {item.wallet
                                                                ?.totalBalance
                                                                ? convertToEightDigitsAfterDecimal(
                                                                    item.wallet
                                                                      ?.totalBalance
                                                                  )
                                                                : "0.00"}
                                                            </span>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      </Dropdown.Item>
                                                    )
                                                  )}
                                                </ul>
                                              </>
                                            ) : null}
                                          </div>
                                          <div className="col-12 px-0">
                                            {favCrypto.length > 0 ? (
                                              <>
                                                {/* <div className="dropTitle mb-1">Crypto</div> */}
                                                {isHidden ? (
                                                  ""
                                                ) : (
                                                  <div className="dropTitle mb-1">
                                                    Crypto
                                                  </div>
                                                )}

                                                <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                                  {/*     */}
                                                  {favCrypto?.map(
                                                    (item, index) => (
                                                      <Dropdown.Item
                                                        as="button"
                                                        key={index}
                                                        className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${
                                                          isHidden &&
                                                          parseFloat(
                                                            item.totalBalance
                                                          ) === 0 &&
                                                          parseFloat(
                                                            item.totalBonus
                                                          ) === 0
                                                            ? "d-none"
                                                            : ""
                                                        }  ${
                                                          item.code ===
                                                          userBalance
                                                            ?.setCurrencyData
                                                            ?.code
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                        onClick={() => {
                                                          // setCurrencyLoader(true)
                                                          setCurrencyLoader(
                                                            (pre) => ({
                                                              ...pre,
                                                              index: index,
                                                              status: false,
                                                              type: "crypto",
                                                            })
                                                          );

                                                          handleChange(item);
                                                        }}
                                                      >
                                                        <div className="d-flex align-items-center">
                                                          {currencyLoader.index ==
                                                            index &&
                                                          currencyLoader.status &&
                                                          currencyLoader.type ==
                                                            "crypto" ? (
                                                            <>
                                                              <Spinner size="sm" />
                                                            </>
                                                          ) : (
                                                            <>
                                                              <img
                                                                className="rounded"
                                                                src={item.icon}
                                                                alt=""
                                                              />
                                                            </>
                                                          )}
                                                          {item.code}
                                                          {/* <img className="infoIcon"
                                                                src="assets/img/header/exclamationYellow.png" alt=""
                                                                onClick={() => setShowUsdtDetailModal(true)} /> */}
                                                        </div>
                                                        <div className="text-end">
                                                          {/* {userDetails.fiatStatus ? item.currency.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                                          {userDetails.fiatStatus
                                                            ? userDetails
                                                                ?.currency
                                                                ?.symbol
                                                            : ""}{" "}
                                                          {item?.totalBalance
                                                            ? convertToEightDigitsAfterDecimal(
                                                                item?.totalBalance
                                                              )
                                                            : "0.00"}
                                                          {userDetails.fiatStatus ? (
                                                            <span className="smallVal">
                                                              {" "}
                                                              {item.wallet
                                                                ?.totalBalance
                                                                ? convertToEightDigitsAfterDecimal(
                                                                    item.wallet
                                                                      ?.totalBalance
                                                                  )
                                                                : "0.00"}
                                                            </span>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {/* <div className="usdtValue2"
                                                                onClick={() => setShowUsdtBonusModal(true)}
                                                            >
                                                                <img
                                                                    src="assets/img/header/lockYellow.png" alt="" />
                                                                $ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
                                                            </div> */}
                                                        </div>
                                                      </Dropdown.Item>
                                                    )
                                                  )}
                                                  {/* {
                                                    currencyExchange?.length ? 
                                                        currencyExchange?.map((item)=> (
                                                          <>
                                                            <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                                <div className="d-flex align-items-center">
                                                                    <img className="rounded" src={item?.icon} alt="" />
                                                                    {item?.name}
                                                                </div>
                                                                <div>
                                                                    {Number(item?.usdAmount)?.toFixed(2)}
                                                                </div>
                                                            </li>
                                                            <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                                <div className="d-flex align-items-center">
                                                                    <img className="rounded" src={item?.icon} alt="" />
                                                                    {item?.name}
                                                                </div>
                                                                <div>
                                                                    {Number(item?.usdAmount)?.toFixed(2)}
                                                                </div>
                                                            </li>
                                                            <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                                <div className="d-flex align-items-center">
                                                                    <img className="rounded" src={item?.icon} alt="" />
                                                                    {item?.name}
                                                                </div>
                                                                <div>
                                                                    {Number(item?.usdAmount)?.toFixed(2)}
                                                                </div>
                                                            </li>
                                                            <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                                <div className="d-flex align-items-center">
                                                                    <img className="rounded" src={item?.icon} alt="" />
                                                                    {item?.name}
                                                                </div>
                                                                <div>
                                                                    {Number(item?.usdAmount)?.toFixed(2)}
                                                                </div>
                                                            </li>
                                                          </>  
                                                        ))
                                                    : ''
                                                } */}
                                                </ul>
                                              </>
                                            ) : null}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          className="innerScrollDiv d-flex justify-content-center align-items-center flex-column"
                                          style={{ gap: "20px" }}
                                        >
                                          {/* <div>
        <img
          src={NoData}
          alt=""
          style={{
            width: "100px",
          }}
        />
        <div className="dropTitle mb-1 text-center mt-3">
          No Data Available
        </div>
      </div> */}
                                          <div style={{ position: "relative" }}>
                                            <span>
                                              <svg
                                                width="74"
                                                height="61"
                                                viewBox="0 0 74 61"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M1.14062 9.43109V3.16768C1.14062 1.97355 2.114 1 3.30794 1H32.993C34.1869 1 35.1606 1.97941 35.1606 3.16768V6.30008C35.1606 7.48366 36.1342 8.46775 37.3281 8.46775H70.6045C71.9239 8.46775 72.9998 9.54354 72.9998 10.8629V53.17C72.9998 56.4666 70.311 59.1556 67.0142 59.1556H64.9196M1.14062 9.43109V45.0887M1.14062 9.43109C1.14062 8.23783 2.11244 7.26431 3.3076 7.26431H27.3076C28.5006 7.26431 29.4744 8.24269 29.4744 9.43111V12.5639C29.4744 13.7495 30.4479 14.7326 31.6427 14.7326H64.9197C66.1131 14.7326 67.0884 15.7137 67.0884 16.8994V56.9886C67.0884 58.1741 66.1053 59.1556 64.9197 59.1556H32.3302"
                                                  stroke="#6A798C"
                                                  strokeWidth="1.87194"
                                                  strokeMiterlimit="22.9256"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                            </span>
                                            <span
                                              style={{
                                                position: "absolute",
                                                bottom: "-6px",
                                                left: "0px",
                                              }}
                                            >
                                              <svg
                                                width="36"
                                                height="36"
                                                viewBox="0 0 36 36"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M5.12993 6.10063C11.9698 -0.739272 23.0596 -0.739272 29.8995 6.10063C36.7394 12.9405 36.7394 24.0303 29.8995 30.8702C23.0596 37.7101 11.9698 37.7101 5.12993 30.8702C-1.70998 24.0303 -1.70998 12.9405 5.12993 6.10063ZM10.0545 7.71085L28.2893 25.9456C31.8345 20.838 31.3316 13.7715 26.7801 9.22007C22.2287 4.66859 15.1622 4.16567 10.0545 7.71085ZM24.9751 29.26L6.74015 11.025C3.19485 16.1326 3.69779 23.1992 8.24934 27.7508C12.8009 32.3024 19.8676 32.8053 24.9751 29.26Z"
                                                  fill="#F8BA28"
                                                />
                                              </svg>
                                            </span>
                                          </div>
                                          <h1
                                            style={{
                                              fontSize: "18px",
                                              color: "#B2B6C5",
                                            }}
                                          >
                                            Oops! There is no data yet!
                                          </h1>
                                        </div>
                                      </>
                                    )}

                                    <div className="col-12">
                                      <div className="row pt-2 pb-1 dropCheckBoxWrapper">
                                        <div className="col-6 dropCheckBox ps-0">
                                          <input
                                            onChange={(e) =>
                                              handleViewFiat(e.target.checked)
                                            }
                                            type="checkbox"
                                            id="fiat"
                                            className="d-none"
                                            checked={isChecked}
                                          />
                                          <label
                                            htmlFor="fiat"
                                            className="checkLabel"
                                          ></label>
                                          <label
                                            htmlFor="fiat"
                                            className="textLabel"
                                          >
                                            View in fiat
                                          </label>
                                        </div>
                                        <div className="col-6 dropCheckBox pe-0 justify-content-end">
                                          <input
                                            type="checkbox"
                                            id="small"
                                            className="d-none"
                                            onChange={(e) =>
                                              setIsHidden(e.target.checked)
                                            }
                                          />
                                          <label
                                            htmlFor="small"
                                            className="checkLabel"
                                          ></label>
                                          <label
                                            htmlFor="small"
                                            className="textLabel"
                                          >
                                            Hide Small
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                              {/* <span className='userBal userBal1'><img src={eth} alt="" />$ 100.00</span> */}
                              <div>
                                <i class="fa-solid fa-sort-down text-white  me-2" style={{ marginBottom:"12px" }}></i>
                              </div>
                            </div>
                          </div>

                          {showLocked ? (
                            <div className="col-auto hover-balance ">
                              <div className="userBalance userbalance-2 d-flex align-items-center justify-content-center">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="headerFiatDrop"
                                  >
                                    <UserAllBlanceComp
                                      setShowLocked={setShowLocked}
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="innerUsdtBtn userBalInnerDrop">
                                    <div className="row mx-0">
                                      <div className="col-12 px-0">
                                        <div className="row mx-0">
                                          <div className="col px-0">
                                            <div className="searchInp">
                                              <img
                                                src="./assets/img/searchGray.png"
                                                alt=""
                                              />
                                              <input
                                                type="text"
                                                className="form-control shadow-none"
                                                placeholder="Search"
                                                value={currencySearch}
                                                // onChange={(e) => searchHandler(e.target.value)}
                                                onChange={(e) =>
                                                  setCurrencySearch(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-auto ps-2 pe-0">
                                            <div
                                              className="plusBtn grayBtn"
                                              onClick={() =>
                                                setShowFavoriteModal(true)
                                              }
                                            >
                                              +
                                              {/* <AddCurrency showFavoriteModal={showFavoriteModal} /> */}
                                            </div>
                                          </div>
                                          <div className="col-12 px-0">
                                            <div className="dropTabs">
                                              <div
                                                className={`tab ${
                                                  selectedDrop ===
                                                  option.CURRENCY
                                                    ? "active"
                                                    : ""
                                                } `}
                                                onClick={() =>
                                                  setSelectedDrop(
                                                    option.CURRENCY
                                                  )
                                                }
                                              >
                                                Currency
                                              </div>
                                              <div
                                                className={`tab ${
                                                  selectedDrop === option.mNFT
                                                    ? "active"
                                                    : ""
                                                } `}
                                                onClick={() =>
                                                  setSelectedDrop(option.mNFT)
                                                }
                                              >
                                                mNFT
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {selectedDrop === option.CURRENCY ? (
                                        <>
                                          <div className="innerScrollDiv">
                                            <div className="col-12 px-0">
                                              {favFiat.length > 0 ? (
                                                <>
                                                  {isHidden ? (
                                                    ""
                                                  ) : (
                                                    <div className="dropTitle mb-1">
                                                      Fiat
                                                    </div>
                                                  )}

                                                  <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                                    {favFiat?.map(
                                                      (item, index) => (
                                                        <Dropdown.Item
                                                          as="button"
                                                          key={index}
                                                          className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${
                                                            isHidden &&
                                                            parseFloat(
                                                              item.totalBalance
                                                            ) === 0 &&
                                                            parseFloat(
                                                              item.totalBonus
                                                            ) === 0
                                                              ? "d-none"
                                                              : ""
                                                          }  ${
                                                            item.code ===
                                                            userBalance
                                                              ?.setCurrencyData
                                                              ?.code
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                          onClick={() => {
                                                            setCurrencyLoader(
                                                              (pre) => ({
                                                                ...pre,
                                                                index: index,
                                                                status: true,
                                                                type: "fiat",
                                                              })
                                                            );
                                                            handleChange(item);
                                                          }}
                                                        >
                                                          <div className="d-flex align-items-center">
                                                            {currencyLoader.index ==
                                                              index &&
                                                            currencyLoader.status &&
                                                            currencyLoader.type ==
                                                              "fiat" ? (
                                                              <>
                                                                <Spinner size="sm" />
                                                              </>
                                                            ) : (
                                                              <>
                                                                <img
                                                                  className="rounded"
                                                                  src={
                                                                    item.icon
                                                                  }
                                                                  alt=""
                                                                />
                                                              </>
                                                            )}
                                                            {item.code}
                                                          </div>
                                                          <div>
                                                            {/* {userDetails.fiatStatus ? item.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                                            {userDetails.fiatStatus
                                                              ? userDetails
                                                                  ?.currency
                                                                  ?.symbol
                                                              : ""}{" "}
                                                            {item?.totalBalance
                                                              ? convertToEightDigitsAfterDecimal(
                                                                  item?.totalBalance
                                                                )
                                                              : "0.00"}
                                                            {userDetails.fiatStatus ? (
                                                              <span className="smallVal">
                                                                {" "}
                                                                {item.wallet
                                                                  ?.totalBalance
                                                                  ? convertToEightDigitsAfterDecimal(
                                                                      item
                                                                        .wallet
                                                                        ?.totalBalance
                                                                    )
                                                                  : "0.00"}
                                                              </span>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </div>
                                                        </Dropdown.Item>
                                                      )
                                                    )}
                                                  </ul>
                                                </>
                                              ) : null}
                                            </div>
                                            <div className="col-12 px-0">
                                              {favCrypto.length > 0 ? (
                                                <>
                                                  {/* <div className="dropTitle mb-1">Crypto</div> */}
                                                  {isHidden ? (
                                                    ""
                                                  ) : (
                                                    <div className="dropTitle mb-1">
                                                      Crypto
                                                    </div>
                                                  )}

                                                  <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                                    {/*     */}
                                                    {favCrypto?.map(
                                                      (item, index) => (
                                                        <Dropdown.Item
                                                          as="button"
                                                          key={index}
                                                          className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${
                                                            isHidden &&
                                                            parseFloat(
                                                              item.totalBalance
                                                            ) === 0 &&
                                                            parseFloat(
                                                              item.totalBonus
                                                            ) === 0
                                                              ? "d-none"
                                                              : ""
                                                          }  ${
                                                            item.code ===
                                                            userBalance
                                                              ?.setCurrencyData
                                                              ?.code
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                          onClick={() => {
                                                            // setCurrencyLoader(true)
                                                            setCurrencyLoader(
                                                              (pre) => ({
                                                                ...pre,
                                                                index: index,
                                                                status: false,
                                                                type: "crypto",
                                                              })
                                                            );

                                                            handleChange(item);
                                                          }}
                                                        >
                                                          <div className="d-flex align-items-center">
                                                            {currencyLoader.index ==
                                                              index &&
                                                            currencyLoader.status &&
                                                            currencyLoader.type ==
                                                              "crypto" ? (
                                                              <>
                                                                <Spinner size="sm" />
                                                              </>
                                                            ) : (
                                                              <>
                                                                <img
                                                                  className="rounded"
                                                                  src={
                                                                    item.icon
                                                                  }
                                                                  alt=""
                                                                />
                                                              </>
                                                            )}
                                                            {item.code}
                                                            {/* <img className="infoIcon"
                                                                  src="assets/img/header/exclamationYellow.png" alt=""
                                                                  onClick={() => setShowUsdtDetailModal(true)} /> */}
                                                          </div>
                                                          <div className="text-end">
                                                            {/* {userDetails.fiatStatus ? item.currency.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                                            {userDetails.fiatStatus
                                                              ? userDetails
                                                                  ?.currency
                                                                  ?.symbol
                                                              : ""}{" "}
                                                            {item?.totalBalance
                                                              ? convertToEightDigitsAfterDecimal(
                                                                  item?.totalBalance
                                                                )
                                                              : "0.00"}
                                                            {userDetails.fiatStatus ? (
                                                              <span className="smallVal">
                                                                {" "}
                                                                {item.wallet
                                                                  ?.totalBalance
                                                                  ? convertToEightDigitsAfterDecimal(
                                                                      item
                                                                        .wallet
                                                                        ?.totalBalance
                                                                    )
                                                                  : "0.00"}
                                                              </span>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* <div className="usdtValue2"
                                                                  onClick={() => setShowUsdtBonusModal(true)}
                                                              >
                                                                  <img
                                                                      src="assets/img/header/lockYellow.png" alt="" />
                                                                  $ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
                                                              </div> */}
                                                          </div>
                                                        </Dropdown.Item>
                                                      )
                                                    )}
                                                    {/* {
                                                      currencyExchange?.length ? 
                                                          currencyExchange?.map((item)=> (
                                                            <>
                                                              <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                                  <div className="d-flex align-items-center">
                                                                      <img className="rounded" src={item?.icon} alt="" />
                                                                      {item?.name}
                                                                  </div>
                                                                  <div>
                                                                      {Number(item?.usdAmount)?.toFixed(2)}
                                                                  </div>
                                                              </li>
                                                              <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                                  <div className="d-flex align-items-center">
                                                                      <img className="rounded" src={item?.icon} alt="" />
                                                                      {item?.name}
                                                                  </div>
                                                                  <div>
                                                                      {Number(item?.usdAmount)?.toFixed(2)}
                                                                  </div>
                                                              </li>
                                                              <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                                  <div className="d-flex align-items-center">
                                                                      <img className="rounded" src={item?.icon} alt="" />
                                                                      {item?.name}
                                                                  </div>
                                                                  <div>
                                                                      {Number(item?.usdAmount)?.toFixed(2)}
                                                                  </div>
                                                              </li>
                                                              <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                                  <div className="d-flex align-items-center">
                                                                      <img className="rounded" src={item?.icon} alt="" />
                                                                      {item?.name}
                                                                  </div>
                                                                  <div>
                                                                      {Number(item?.usdAmount)?.toFixed(2)}
                                                                  </div>
                                                              </li>
                                                            </>  
                                                          ))
                                                      : ''
                                                  } */}
                                                  </ul>
                                                </>
                                              ) : null}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="innerScrollDiv d-flex justify-content-center align-items-center flex-column"
                                            style={{ gap: "20px" }}
                                          >
                                            {/* <div>
          <img
            src={NoData}
            alt=""
            style={{
              width: "100px",
            }}
          />
          <div className="dropTitle mb-1 text-center mt-3">
            No Data Available
          </div>
        </div> */}
                                            <div
                                              style={{ position: "relative" }}
                                            >
                                              <span>
                                                <svg
                                                  width="74"
                                                  height="61"
                                                  viewBox="0 0 74 61"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M1.14062 9.43109V3.16768C1.14062 1.97355 2.114 1 3.30794 1H32.993C34.1869 1 35.1606 1.97941 35.1606 3.16768V6.30008C35.1606 7.48366 36.1342 8.46775 37.3281 8.46775H70.6045C71.9239 8.46775 72.9998 9.54354 72.9998 10.8629V53.17C72.9998 56.4666 70.311 59.1556 67.0142 59.1556H64.9196M1.14062 9.43109V45.0887M1.14062 9.43109C1.14062 8.23783 2.11244 7.26431 3.3076 7.26431H27.3076C28.5006 7.26431 29.4744 8.24269 29.4744 9.43111V12.5639C29.4744 13.7495 30.4479 14.7326 31.6427 14.7326H64.9197C66.1131 14.7326 67.0884 15.7137 67.0884 16.8994V56.9886C67.0884 58.1741 66.1053 59.1556 64.9197 59.1556H32.3302"
                                                    stroke="#6A798C"
                                                    strokeWidth="1.87194"
                                                    strokeMiterlimit="22.9256"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  />
                                                </svg>
                                              </span>
                                              <span
                                                style={{
                                                  position: "absolute",
                                                  bottom: "-6px",
                                                  left: "0px",
                                                }}
                                              >
                                                <svg
                                                  width="36"
                                                  height="36"
                                                  viewBox="0 0 36 36"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M5.12993 6.10063C11.9698 -0.739272 23.0596 -0.739272 29.8995 6.10063C36.7394 12.9405 36.7394 24.0303 29.8995 30.8702C23.0596 37.7101 11.9698 37.7101 5.12993 30.8702C-1.70998 24.0303 -1.70998 12.9405 5.12993 6.10063ZM10.0545 7.71085L28.2893 25.9456C31.8345 20.838 31.3316 13.7715 26.7801 9.22007C22.2287 4.66859 15.1622 4.16567 10.0545 7.71085ZM24.9751 29.26L6.74015 11.025C3.19485 16.1326 3.69779 23.1992 8.24934 27.7508C12.8009 32.3024 19.8676 32.8053 24.9751 29.26Z"
                                                    fill="#F8BA28"
                                                  />
                                                </svg>
                                              </span>
                                            </div>
                                            <h1
                                              style={{
                                                fontSize: "18px",
                                                color: "#B2B6C5",
                                              }}
                                            >
                                              Oops! There is no data yet!
                                            </h1>
                                          </div>
                                        </>
                                      )}

                                      <div className="col-12">
                                        <div className="row pt-2 pb-1 dropCheckBoxWrapper">
                                          <div className="col-6 dropCheckBox ps-0">
                                            <input
                                              onChange={(e) =>
                                                handleViewFiat(e.target.checked)
                                              }
                                              type="checkbox"
                                              id="fiat"
                                              className="d-none"
                                              checked={isChecked}
                                            />
                                            <label
                                              htmlFor="fiat"
                                              className="checkLabel"
                                            ></label>
                                            <label
                                              htmlFor="fiat"
                                              className="textLabel"
                                            >
                                              View in fiat
                                            </label>
                                          </div>
                                          <div className="col-6 dropCheckBox pe-0 justify-content-end">
                                            <input
                                              type="checkbox"
                                              id="small"
                                              className="d-none"
                                              onChange={(e) =>
                                                setIsHidden(e.target.checked)
                                              }
                                            />
                                            <label
                                              htmlFor="small"
                                              className="checkLabel"
                                            ></label>
                                            <label
                                              htmlFor="small"
                                              className="textLabel"
                                            >
                                              Hide Small
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                                {/* <span className='userBal userBal1'><img src={eth} alt="" />$ 100.00</span> */}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-auto">
                          <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                            <div className="iconHeader">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                  className="iconHeader dropDown-icon"
                                >
                                  <img
                                    className="h-100 w-100 object-fit-contain"
                                    src={
                                      userDetails?.image
                                        ? userDetails?.image
                                        : Avatar
                                    }
                                    alt=""
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropDownMenu profileDropMenu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      setAccountTab({
                                        selectedTab: ACCOUNT_TABS.PROFILE,
                                      });
                                      navigate(ROUTE_CONST.PROFILE);
                                    }}
                                    className={`dropdownItem ${
                                      accountTab?.selectedTab ===
                                      ACCOUNT_TABS.PROFILE
                                        ? "active"
                                        : ""
                                    }`}
                                    as="button"
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="dropIcon">
                                        <img src={userDropProfile} alt="" />
                                      </div>
                                      <div className="DropTxt">
                                        {desktopHeader.ACCOUNT_OPTIONS.Profile}
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setAccountTab({
                                        selectedTab: ACCOUNT_TABS.WALLET,
                                      });
                                      navigate(ROUTE_CONST.WALLET);
                                    }}
                                    className={`dropdownItem ${
                                      accountTab?.selectedTab ===
                                      ACCOUNT_TABS.WALLET
                                        ? "active"
                                        : ""
                                    }`}
                                    as="button"
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="dropIcon">
                                        <img src={userWallet} alt="" />
                                      </div>
                                      <div className="DropTxt">
                                        {desktopHeader.ACCOUNT_OPTIONS.Wallet}
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setAccountTab({
                                        selectedTab: ACCOUNT_TABS.AFFILIATE,
                                      });
                                      navigate(ROUTE_CONST.AFFILIATE_PAGE);
                                    }}
                                    className={`dropdownItem ${
                                      accountTab?.selectedTab ===
                                      ACCOUNT_TABS.AFFILIATE
                                        ? "active"
                                        : ""
                                    }`}
                                    as="button"
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="dropIcon">
                                        <img src={userAffiliate} alt="" />
                                      </div>
                                      <div className="DropTxt">
                                        {
                                          desktopHeader.ACCOUNT_OPTIONS
                                            .Affiliate
                                        }
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                                    onClick={() => {
                                      setAccountTab({
                                        selectedTab: ACCOUNT_TABS.DEPOSIT
                                      })
                                      navigate(ROUTE_CONST.PROFILE)
                                    }} className={`dropdownItem ${accountTab?.selectedTab === ACCOUNT_TABS.DEPOSIT ? 'active' : ''}`} as="button">
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="dropIcon">
                                        <img src={Deposits} alt="" />
                                      </div>
                                      <div className="DropTxt">
                                        {desktopHeader.ACCOUNT_OPTIONS.Deposits}
                                      </div>
                                    </div>
                                  </Dropdown.Item> */}
                                  <Dropdown.Item
                                    onClick={() => {
                                      setAccountTab({
                                        selectedTab: ACCOUNT_TABS.WITHDRAW,
                                      });
                                      navigate(
                                        `${ROUTE_CONST.WALLET}?tab=Withdraw`
                                      );
                                    }}
                                    className={`dropdownItem ${
                                      accountTab?.selectedTab ===
                                      ACCOUNT_TABS.WITHDRAW
                                        ? "active"
                                        : ""
                                    }`}
                                    as="button"
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="dropIcon">
                                        <img src={userWithdraw} alt="" />
                                      </div>
                                      <div className="DropTxt">
                                        {
                                          desktopHeader.ACCOUNT_OPTIONS
                                            .Withdrawals
                                        }
                                      </div>
                                    </div>
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    onClick={() => {
                                      setAccountTab({
                                        selectedTab: ACCOUNT_TABS.TRANSACTIONS,
                                      });
                                      navigate(
                                        `${ROUTE_CONST.WALLET}?tab=Transaction`
                                      );
                                    }}
                                    className={`dropdownItem ${
                                      accountTab?.selectedTab ===
                                      ACCOUNT_TABS.TRANSACTIONS
                                        ? "active"
                                        : ""
                                    }`}
                                    as="button"
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="dropIcon">
                                        <img src={userTransactions} alt="" />
                                      </div>
                                      <div className="DropTxt">
                                        {
                                          desktopHeader.ACCOUNT_OPTIONS
                                            .Transactions
                                        }
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className={`dropdownItem ${
                                      accountTab?.selectedTab ===
                                      ACCOUNT_TABS.SETTING
                                        ? "active"
                                        : ""
                                    }`}
                                    as="button"
                                    onClick={() => {
                                      setAccountTab({
                                        selectedTab: ACCOUNT_TABS.SETTING,
                                      });
                                      navigate(ROUTE_CONST.PROFILE2);
                                    }}
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="dropIcon">
                                        <img src={userSetting} alt="" />
                                      </div>
                                      <div className="DropTxt">
                                        {desktopHeader.ACCOUNT_OPTIONS.Settings}
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdownItem"
                                    as="button"
                                    onClick={() => setIsLogoutOpen(true)}
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="dropIcon">
                                        <img src={userLogout} alt="" />
                                      </div>
                                      <div className="DropTxt">
                                        {desktopHeader.ACCOUNT_OPTIONS.Log_Out}
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-auto"
                          onClick={() => setIsNotificationOpen(true)}
                        >
                          <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                            <div
                              className={`iconHeader iconHeaderN ${
                                notifyStatus?.status ? "active" : ""
                              }`}
                            >
                              <img src={NotificationBell} alt="" />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {!token && (
                      <div className="col-auto loginSignUpBtns d-flex align-items-center gap-3">
                        <button onClick={handleRegister} className="authBtn ">
                          {desktopHeader.Sign_up}
                        </button>
                        <button onClick={handleLogin} className="authBtn fill">
                          {desktopHeader.login}
                        </button>
                      </div>
                    )}
                    <div
                      className="col-auto"
                      data-bs-toggle="offcanvas"
                      href="#message"
                      role="button"
                      aria-controls="message"
                      onClick={() => {
                        socket.emit("joinRoom", { msg: null });
                        document.body.classList.toggle("showMsgOffcanvas");
                        setIsChatOpen(true);
                      }}
                    >
                      <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                        <div className="iconHeader">
                          <img src={Comment} alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                        <div
                          className=" cursor-pointer iconHeader"
                          onClick={() => {
                            setShowLanguage(true);
                            setLanguageSelectedTab(TABS_OPTIONS.language);
                          }}
                        >
                          <img src={Country} alt="" />
                        </div>

                        {/* <div className="iconHeader">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              className="iconHeader dropDown-icon"
                            >
                              <img
                                className="h-100 w-100 object-fit-contain"
                                src={Country}
                                alt=""
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropDownMenu languageDropMenu">
                              <div className="searchInpDrop position-relative">
                                <input
                                  type="text"
                                  placeholder="Search"
                                  className="inp"
                                />
                                <button
                                  type="button"
                                  className="searchImgg border-0 shadow-none p-0 m-0 bg-transparent"
                                >
                                  <svg
                                    stroke="#93A6C4"
                                    height="22px"
                                    widths="22px"
                                    fill="#93A6C4"
                                    stroke-width="0"
                                    viewBox="0 0 512 512"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
                                  </svg>
                                </button>
                              </div>
                              <div className="currency_search_list ">
                                <ul className=" m-0 d-flex flex-column gap-2 p-0 mt-3">
                                  <li
                                    className={`cursor-pointer d-flex justify-content-between align-items-center dropList ${
                                      selectedLanguage === "english"
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={(e) => {
                                      languageHandler({
                                        displayName: "English",
                                        symbol: "en",
                                      });
                                      handleLanguageChange(e, "english");
                                    }}
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <img src={c1} alt="" />
                                      English
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="language"
                                        id="english"
                                        checked={selectedLanguage === "english"}
                                        onChange={(e) =>
                                          handleLanguageChange(e, "english")
                                        }
                                      />
                                    </div>
                                  </li>
                                  <li
                                    className={`cursor-pointer d-flex justify-content-between align-items-center dropList ${
                                      selectedLanguage === "french"
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={(e) => {
                                      languageHandler({
                                        displayName: "Français",
                                        symbol: "fr",
                                      });
                                      handleLanguageChange(e, "french");
                                    }}
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <img src={france} alt="" />
                                      French
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="language"
                                        id="french"
                                        checked={selectedLanguage === "french"}
                                        onChange={(e) =>
                                          handleLanguageChange(e, "french")
                                        }
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* for mobile */}

                  <div className="d-flex d-lg-none align-items-center justify-content-end">
                    {!token ? (
                      <>
                        <button onClick={handleRegister} className="mobAuthBtn">
                          {desktopHeader.Sign_up}
                        </button>
                        <button
                          onClick={handleLogin}
                          className="mobAuthBtn dark"
                        >
                          {desktopHeader.login}
                        </button>
                        {/* <button className="mobAuthBtn " onClick={() => {
                            setShowLanguage(true);
                            setLanguageSelectedTab(TABS_OPTIONS.language);
                          }}>
                            <img src="./assets/img/globe.png" alt="" />
                          </button> */}
                      </>
                    ) : (
                      <>
                        {/* after login */}

                        {/* <div className="userBalance d-flex align-items-center justify-content-between">

                            <Dropdown >
                              <Dropdown.Toggle variant="success" id="dropdown-basic" className='headerFiatDrop '>
                                <UserBalanceComp />

                              </Dropdown.Toggle>
                              <Dropdown.Menu className='innerUsdtBtn userBalInnerDrop'>
                                <div className="row mx-0">
                                  <div className="col-12 px-0">
                                    <div className="row mx-0">
                                      <div className="col px-0">
                                        <div className="searchInp">
                                          <img src="./assets/img/searchGray.png" alt="" />
                                          <input
                                            type="text"
                                            className="form-control shadow-none"
                                            placeholder="Search"
                                            value={currencySearch}
                                             onChange={(e) => searchHandler(e.target.value)}
                                            onChange={(e) => setCurrencySearch(e.target.value)}

                                          />
                                        </div>
                                      </div>
                                      <div className="col-auto ps-2 pe-0">
                                        <div className="plusBtn grayBtn" onClick={() => setShowFavoriteModal(true)}>
                                          +
                                          <AddCurrency showFavoriteModal={showFavoriteModal} />
                                        </div>
                                      </div>
                                      <div className="col-12 px-0">
                                        <div className="dropTabs">
                                          <div className={`tab ${selectedDrop === option.CURRENCY ? "active" : ""} `} onClick={() => setSelectedDrop(option.CURRENCY)}>Currency</div>
                                          <div className={`tab ${selectedDrop === option.mNFT ? "active" : ""} `} onClick={() => setSelectedDrop(option.mNFT)}>mNFT</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {
                                    selectedDrop === option.CURRENCY ? (
                                      <>
                                        <div className="innerScrollDiv">
                                          <div className="col-12 px-0">
                                            {favFiat.length > 0 ? (
                                              <>
                                                {isHidden ? "" : <div className="dropTitle mb-1">Fiat</div>}

                                                <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                                  {
                                                    favFiat?.map((item, index) => (
                                                      <Dropdown.Item as="button" key={index} className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${isHidden && (parseFloat(item.totalBalance) === 0 && parseFloat(item.totalBonus) === 0) ? "d-none" : ""}  ${item.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}
                                                        onClick={() => {
                                                          setCurrencyLoader((pre) => ({ ...pre, index: index, status: true, type: "fiat" }))
                                                          handleChange(item)
                                                        }}
                                                      >
                                                        <div className="d-flex align-items-center">

                                                          {
                                                            currencyLoader.index == index && currencyLoader.status && currencyLoader.type == 'fiat' ? (
                                                              <><Spinner size="sm" /></>
                                                            ) : (<>
                                                              <img className="rounded" src={item.icon} alt="" />
                                                            </>)
                                                          }
                                                          {item.code}
                                                        </div>
                                                        <div>
                                                          {userDetails.fiatStatus ? item.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'}
                                                          {userDetails.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}

                                                          {userDetails.fiatStatus ? (
                                                            <span className="smallVal"> {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item.wallet?.totalBalance) : '0.00'}
                                                            </span>
                                                          ) : (
                                                            ''
                                                          )
                                                          }
                                                        </div>
                                                      </Dropdown.Item>
                                                    ))}
                                                </ul>
                                              </>) : null}
                                          </div>
                                          <div className="col-12 px-0">
                                            {favCrypto.length > 0 ? (
                                              <>
                                                <div className="dropTitle mb-1">Crypto</div>
                                                {isHidden ? "" : <div className="dropTitle mb-1">Crypto</div>}

                                                <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                                     
                                                  {favCrypto?.map((item, index) => (

                                                    <Dropdown.Item
                                                      as="button"
                                                      key={index}
                                                      className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${isHidden && (parseFloat(item.totalBalance) === 0 && parseFloat(item.totalBonus) === 0) ? "d-none" : ""}  ${item.code === userBalance?.setCurrencyData?.code ? 'active' : ''}`}
                                                      onClick={() => {
                                                         setCurrencyLoader(true)
                                                        setCurrencyLoader((pre) => ({ ...pre, index: index, status: false, type: "crypto" }))

                                                        handleChange(item)
                                                      }}
                                                    >
                                                      <div className="d-flex align-items-center">

                                                        {
                                                          currencyLoader.index == index && currencyLoader.status && currencyLoader.type == 'crypto' ? (
                                                            <><Spinner size="sm" /></>
                                                          ) : (<>
                                                            <img className="rounded" src={item.icon} alt="" />
                                                          </>)
                                                        }
                                                        {item.code}
                                                        <img className="infoIcon"
                                                            src="assets/img/header/exclamationYellow.png" alt=""
                                                            onClick={() => setShowUsdtDetailModal(true)} />
                                                      </div>
                                                      <div className="text-end">
                                                        {userDetails.fiatStatus ? item.currency.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'}
                                                        {userDetails.fiatStatus ? userDetails?.currency?.symbol : ""} {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}
                                                        {userDetails.fiatStatus ? <span className="smallVal"> {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item.wallet?.totalBalance) : '0.00'}</span> : ""}
                                                        <div className="usdtValue2"
                                                            onClick={() => setShowUsdtBonusModal(true)}
                                                        >
                                                            <img
                                                                src="assets/img/header/lockYellow.png" alt="" />
                                                            $ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
                                                        </div>
                                                      </div>
                                                    </Dropdown.Item>
                                                  ))}
                                                  {
                                                currencyExchange?.length ? 
                                                    currencyExchange?.map((item)=> (
                                                      <>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                      </>  
                                                    ))
                                                : ''
                                            }
                                                </ul>
                                              </>) : null}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="innerScrollDiv d-flex justify-content-center align-items-center">
                                          <div>
                                            <img
                                              src={NoData}
                                              alt=""
                                              style={{
                                                width: "100px"
                                              }}
                                            />
                                            <div className="dropTitle mb-1 text-center mt-3">No Data Available</div>
                                          </div>


                                        </div>
                                      </>
                                    )
                                  }

                                  <div className="col-12">
                                    <div className="row pt-2 pb-1 dropCheckBoxWrapper">
                                      <div className="col-6 dropCheckBox ps-0">
                                        <input onChange={(e) => handleViewFiat(e.target.checked)} type="checkbox" id="fiat" className="d-none" checked={isChecked} />
                                        <label htmlFor="fiat" className="checkLabel"></label>
                                        <label htmlFor="fiat" className="textLabel">View in fiat</label>
                                      </div>
                                      <div className="col-6 dropCheckBox pe-0 justify-content-end">
                                        <input type="checkbox" id="small" className="d-none"
                                          onChange={(e) => setIsHidden(e.target.checked)}
                                        />
                                        <label htmlFor="small" className="checkLabel"></label>
                                        <label htmlFor="small" className="textLabel">Hide Small</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                            <span className='plusBtn d-flex justify-content-center align-items-center'
                              onClick={() => {
                                setWalletModalObject((prev) => ({
                                  isWalletOpen: true,
                                  selectedTab: WALLET_MODAL_TABS.DEPOSIT
                                }))
                                setQuery({ action: "wallet" })
                              }}>
                              <img src={plusIcon} alt="plus icon" className='object-fit-contain' />
                            </span>
                            <span className='userBal'>$ 100.00</span>
                          </div> */}
                        <div className="userBalance d-lg-flex d-none align-items-center justify-content-between">
                          <span
                            className="plusBtn d-flex justify-content-center align-items-center"
                            onClick={() => {
                              setWalletModalObject((prev) => ({
                                isWalletOpen: true,
                                selectedTab: WALLET_MODAL_TABS.DEPOSIT,
                              }));
                              setQuery({ action: "wallet" });
                            }}
                          >
                            <img
                              src={plusIcon}
                              alt="plus icon"
                              className="object-fit-contain"
                            />
                          </span>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              className="headerFiatDrop"
                            >
                              <UserBalanceComp setShowLocked={setShowLocked} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="innerUsdtBtn userBalInnerDrop">
                              <div className="row mx-0">
                                <div className="col-12 px-0">
                                  <div className="row mx-0">
                                    <div className="col px-0">
                                      <div className="searchInp">
                                        <img
                                          src="./assets/img/searchGray.png"
                                          alt=""
                                        />
                                        <input
                                          type="text"
                                          className="form-control shadow-none"
                                          placeholder="Search"
                                          value={currencySearch}
                                          // onChange={(e) => searchHandler(e.target.value)}
                                          onChange={(e) =>
                                            setCurrencySearch(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-auto ps-2 pe-0">
                                      <div
                                        className="plusBtn grayBtn"
                                        onClick={() =>
                                          setShowFavoriteModal(true)
                                        }
                                      >
                                        +
                                        {/* <AddCurrency showFavoriteModal={showFavoriteModal} /> */}
                                      </div>
                                    </div>
                                    <div className="col-12 px-0">
                                      <div className="dropTabs">
                                        <div
                                          className={`tab ${
                                            selectedDrop === option.CURRENCY
                                              ? "active"
                                              : ""
                                          } `}
                                          onClick={() =>
                                            setSelectedDrop(option.CURRENCY)
                                          }
                                        >
                                          Currency
                                        </div>
                                        <div
                                          className={`tab ${
                                            selectedDrop === option.mNFT
                                              ? "active"
                                              : ""
                                          } `}
                                          onClick={() =>
                                            setSelectedDrop(option.mNFT)
                                          }
                                        >
                                          mNFT
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {selectedDrop === option.CURRENCY ? (
                                  <>
                                    <div className="innerScrollDiv">
                                      <div className="col-12 px-0">
                                        {favFiat.length > 0 ? (
                                          <>
                                            {isHidden ? (
                                              ""
                                            ) : (
                                              <div className="dropTitle mb-1">
                                                Fiat
                                              </div>
                                            )}

                                            <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                              {favFiat?.map((item, index) => (
                                                <Dropdown.Item
                                                  as="button"
                                                  key={index}
                                                  className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${
                                                    isHidden &&
                                                    parseFloat(
                                                      item.totalBalance
                                                    ) === 0 &&
                                                    parseFloat(
                                                      item.totalBonus
                                                    ) === 0
                                                      ? "d-none"
                                                      : ""
                                                  }  ${
                                                    item.code ===
                                                    userBalance?.setCurrencyData
                                                      ?.code
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  onClick={() => {
                                                    setCurrencyLoader(
                                                      (pre) => ({
                                                        ...pre,
                                                        index: index,
                                                        status: true,
                                                        type: "fiat",
                                                      })
                                                    );
                                                    handleChange(item);
                                                  }}
                                                >
                                                  <div className="d-flex align-items-center">
                                                    {currencyLoader.index ==
                                                      index &&
                                                    currencyLoader.status &&
                                                    currencyLoader.type ==
                                                      "fiat" ? (
                                                      <>
                                                        <Spinner size="sm" />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <img
                                                          className="rounded"
                                                          src={item.icon}
                                                          alt=""
                                                        />
                                                      </>
                                                    )}
                                                    {item.code}
                                                  </div>
                                                  <div>
                                                    {/* {userDetails.fiatStatus ? item.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                                    {userDetails.fiatStatus
                                                      ? userDetails?.currency
                                                          ?.symbol
                                                      : ""}{" "}
                                                    {item?.totalBalance
                                                      ? convertToEightDigitsAfterDecimal(
                                                          item?.totalBalance
                                                        )
                                                      : "0.00"}
                                                    {userDetails.fiatStatus ? (
                                                      <span className="smallVal">
                                                        {" "}
                                                        {item.wallet
                                                          ?.totalBalance
                                                          ? convertToEightDigitsAfterDecimal(
                                                              item.wallet
                                                                ?.totalBalance
                                                            )
                                                          : "0.00"}
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </Dropdown.Item>
                                              ))}
                                            </ul>
                                          </>
                                        ) : null}
                                      </div>
                                      <div className="col-12 px-0">
                                        {favCrypto.length > 0 ? (
                                          <>
                                            {/* <div className="dropTitle mb-1">Crypto</div> */}
                                            {isHidden ? (
                                              ""
                                            ) : (
                                              <div className="dropTitle mb-1">
                                                Crypto
                                              </div>
                                            )}

                                            <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                              {/*     */}
                                              {favCrypto?.map((item, index) => (
                                                <Dropdown.Item
                                                  as="button"
                                                  key={index}
                                                  className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${
                                                    isHidden &&
                                                    parseFloat(
                                                      item.totalBalance
                                                    ) === 0 &&
                                                    parseFloat(
                                                      item.totalBonus
                                                    ) === 0
                                                      ? "d-none"
                                                      : ""
                                                  }  ${
                                                    item.code ===
                                                    userBalance?.setCurrencyData
                                                      ?.code
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  onClick={() => {
                                                    // setCurrencyLoader(true)
                                                    setCurrencyLoader(
                                                      (pre) => ({
                                                        ...pre,
                                                        index: index,
                                                        status: false,
                                                        type: "crypto",
                                                      })
                                                    );

                                                    handleChange(item);
                                                  }}
                                                >
                                                  <div className="d-flex align-items-center">
                                                    {currencyLoader.index ==
                                                      index &&
                                                    currencyLoader.status &&
                                                    currencyLoader.type ==
                                                      "crypto" ? (
                                                      <>
                                                        <Spinner size="sm" />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <img
                                                          className="rounded"
                                                          src={item.icon}
                                                          alt=""
                                                        />
                                                      </>
                                                    )}
                                                    {item.code}
                                                    {/* <img className="infoIcon"
                                                            src="assets/img/header/exclamationYellow.png" alt=""
                                                            onClick={() => setShowUsdtDetailModal(true)} /> */}
                                                  </div>
                                                  <div className="text-end">
                                                    {/* {userDetails.fiatStatus ? item.currency.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                                    {userDetails.fiatStatus
                                                      ? userDetails?.currency
                                                          ?.symbol
                                                      : ""}{" "}
                                                    {item?.totalBalance
                                                      ? convertToEightDigitsAfterDecimal(
                                                          item?.totalBalance
                                                        )
                                                      : "0.00"}
                                                    {userDetails.fiatStatus ? (
                                                      <span className="smallVal">
                                                        {" "}
                                                        {item.wallet
                                                          ?.totalBalance
                                                          ? convertToEightDigitsAfterDecimal(
                                                              item.wallet
                                                                ?.totalBalance
                                                            )
                                                          : "0.00"}
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {/* <div className="usdtValue2"
                                                            onClick={() => setShowUsdtBonusModal(true)}
                                                        >
                                                            <img
                                                                src="assets/img/header/lockYellow.png" alt="" />
                                                            $ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
                                                        </div> */}
                                                  </div>
                                                </Dropdown.Item>
                                              ))}
                                              {/* {
                                                currencyExchange?.length ? 
                                                    currencyExchange?.map((item)=> (
                                                      <>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                      </>  
                                                    ))
                                                : ''
                                            } */}
                                            </ul>
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="innerScrollDiv d-flex justify-content-center align-items-center flex-column"
                                      style={{ gap: "20px" }}
                                    >
                                      {/* <div>
    <img
      src={NoData}
      alt=""
      style={{
        width: "100px",
      }}
    />
    <div className="dropTitle mb-1 text-center mt-3">
      No Data Available
    </div>
  </div> */}
                                      <div style={{ position: "relative" }}>
                                        <span>
                                          <svg
                                            width="74"
                                            height="61"
                                            viewBox="0 0 74 61"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1.14062 9.43109V3.16768C1.14062 1.97355 2.114 1 3.30794 1H32.993C34.1869 1 35.1606 1.97941 35.1606 3.16768V6.30008C35.1606 7.48366 36.1342 8.46775 37.3281 8.46775H70.6045C71.9239 8.46775 72.9998 9.54354 72.9998 10.8629V53.17C72.9998 56.4666 70.311 59.1556 67.0142 59.1556H64.9196M1.14062 9.43109V45.0887M1.14062 9.43109C1.14062 8.23783 2.11244 7.26431 3.3076 7.26431H27.3076C28.5006 7.26431 29.4744 8.24269 29.4744 9.43111V12.5639C29.4744 13.7495 30.4479 14.7326 31.6427 14.7326H64.9197C66.1131 14.7326 67.0884 15.7137 67.0884 16.8994V56.9886C67.0884 58.1741 66.1053 59.1556 64.9197 59.1556H32.3302"
                                              stroke="#6A798C"
                                              strokeWidth="1.87194"
                                              strokeMiterlimit="22.9256"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </span>
                                        <span
                                          style={{
                                            position: "absolute",
                                            bottom: "-6px",
                                            left: "0px",
                                          }}
                                        >
                                          <svg
                                            width="36"
                                            height="36"
                                            viewBox="0 0 36 36"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M5.12993 6.10063C11.9698 -0.739272 23.0596 -0.739272 29.8995 6.10063C36.7394 12.9405 36.7394 24.0303 29.8995 30.8702C23.0596 37.7101 11.9698 37.7101 5.12993 30.8702C-1.70998 24.0303 -1.70998 12.9405 5.12993 6.10063ZM10.0545 7.71085L28.2893 25.9456C31.8345 20.838 31.3316 13.7715 26.7801 9.22007C22.2287 4.66859 15.1622 4.16567 10.0545 7.71085ZM24.9751 29.26L6.74015 11.025C3.19485 16.1326 3.69779 23.1992 8.24934 27.7508C12.8009 32.3024 19.8676 32.8053 24.9751 29.26Z"
                                              fill="#F8BA28"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                      <h1
                                        style={{
                                          fontSize: "18px",
                                          color: "#B2B6C5",
                                        }}
                                      >
                                        Oops! There is no data yet!
                                      </h1>
                                    </div>
                                  </>
                                )}

                                <div className="col-12">
                                  <div className="row pt-2 pb-1 dropCheckBoxWrapper">
                                    <div className="col-6 dropCheckBox ps-0">
                                      <input
                                        onChange={(e) =>
                                          handleViewFiat(e.target.checked)
                                        }
                                        type="checkbox"
                                        id="fiat"
                                        className="d-none"
                                        checked={isChecked}
                                      />
                                      <label
                                        htmlFor="fiat"
                                        className="checkLabel"
                                      ></label>
                                      <label
                                        htmlFor="fiat"
                                        className="textLabel"
                                      >
                                        View in fiat
                                      </label>
                                    </div>
                                    <div className="col-6 dropCheckBox pe-0 justify-content-end">
                                      <input
                                        type="checkbox"
                                        id="small"
                                        className="d-none"
                                        onChange={(e) =>
                                          setIsHidden(e.target.checked)
                                        }
                                      />
                                      <label
                                        htmlFor="small"
                                        className="checkLabel"
                                      ></label>
                                      <label
                                        htmlFor="small"
                                        className="textLabel"
                                      >
                                        Hide Small
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* <span className='userBal userBal1'><img src={eth} alt="" />$ 100.00</span> */}
                        </div>
                        <div className="userCurrencyImg d-flex align-items-center justify-content-between">
                          <div className="row justify-content-end mx-0 gx-2">
                            <div
                              onClick={() => {
                                setResultObject({
                                  ...resultObject,
                                  isResultOpen: true,
                                });
                              }}
                              className={`col-3`}
                            >
                              <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                                <div className="iconHeader">
                                  <img src={searchIcon} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className={`col-3`}>
                              <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                                <div className="iconHeader">
                                  <img src={HeaderGiftIcon} alt="" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-3"
                              onClick={() => setIsNotificationOpen(true)}
                            >
                              <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                                <div
                                  className={`iconHeader iconHeaderN rounded-circle ${
                                    notifyStatus?.status ? "active" : ""
                                  }`}
                                >
                                  <img src={NotificationBell} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div class="search-icon d-flex align-items-center justify-content-center rounded-circle">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="iconHeader dropDown-icon"
                                  >
                                    <div className="headerIcons">
                                      <img
                                        src={
                                          userDetails?.image
                                            ? userDetails?.image
                                            : Avatar
                                        }
                                        alt="currency icon"
                                        className="h-100 w-100 object-fit-contain"
                                      />
                                    </div>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="dropDownMenu profileDropMenu">
                                    <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.PROFILE,
                                        });
                                        navigate(ROUTE_CONST.PROFILE);
                                      }}
                                      className={`dropdownItem ${
                                        accountTab?.selectedTab ===
                                        ACCOUNT_TABS.PROFILE
                                          ? "active"
                                          : ""
                                      }`}
                                      as="button"
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={userDropProfile} alt="" />
                                        </div>
                                        <div className="DropTxt">Profile</div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.WALLET,
                                        });
                                        navigate(ROUTE_CONST.WALLET);
                                      }}
                                      className={`dropdownItem ${
                                        accountTab?.selectedTab ===
                                        ACCOUNT_TABS.WALLET
                                          ? "active"
                                          : ""
                                      }`}
                                      as="button"
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={userWallet} alt="" />
                                        </div>
                                        <div className="DropTxt">Wallet</div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.AFFILIATE,
                                        });
                                        navigate(ROUTE_CONST.PROFILE);
                                      }}
                                      className={`dropdownItem ${
                                        accountTab?.selectedTab ===
                                        ACCOUNT_TABS.AFFILIATE
                                          ? "active"
                                          : ""
                                      }`}
                                      as="button"
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={userAffiliate} alt="" />
                                        </div>
                                        <div className="DropTxt">Affiliate</div>
                                      </div>
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.DEPOSIT,
                                        });
                                        navigate(ROUTE_CONST.PROFILE);
                                      }}
                                      className={`dropdownItem ${
                                        accountTab?.selectedTab ===
                                        ACCOUNT_TABS.DEPOSIT
                                          ? "active"
                                          : ""
                                      }`}
                                      as="button"
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={Deposits} alt="" />
                                        </div>
                                        <div className="DropTxt">Deposits</div>
                                      </div>
                                    </Dropdown.Item> */}
                                    <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.WITHDRAW,
                                        });
                                        navigate(
                                          `${ROUTE_CONST.WALLET}?tab=Withdraw`
                                        );
                                      }}
                                      className={`dropdownItem ${
                                        accountTab?.selectedTab ===
                                        ACCOUNT_TABS.WITHDRAW
                                          ? "active"
                                          : ""
                                      }`}
                                      as="button"
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={userWithdraw} alt="" />
                                        </div>
                                        <div className="DropTxt">
                                          {
                                            desktopHeader.ACCOUNT_OPTIONS
                                              .Withdrawals
                                          }
                                        </div>
                                      </div>
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab:
                                            ACCOUNT_TABS.TRANSACTIONS,
                                        });
                                        navigate(
                                          `${ROUTE_CONST.WALLET}?tab=Transaction`
                                        );
                                      }}
                                      className={`dropdownItem ${
                                        accountTab?.selectedTab ===
                                        ACCOUNT_TABS.TRANSACTIONS
                                          ? "active"
                                          : ""
                                      }`}
                                      as="button"
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={userTransactions} alt="" />
                                        </div>
                                        <div className="DropTxt">
                                          Transactions
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className={`dropdownItem ${
                                        accountTab?.selectedTab ===
                                        ACCOUNT_TABS.SETTING
                                          ? "active"
                                          : ""
                                      }`}
                                      as="button"
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.SETTING,
                                        });
                                        navigate(ROUTE_CONST.PROFILE2);
                                      }}
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={userSetting} alt="" />
                                        </div>
                                        <div className="DropTxt">
                                          {desktopaccountPageLabels.SETTINGS}
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="dropdownItem"
                                      as="button"
                                      onClick={() => setIsLogoutOpen(true)}
                                    >
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={userLogout} alt="" />
                                        </div>
                                        <div className="DropTxt">Sign Out</div>
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            {/* <div className="col-auto pe-0" onClick={() => {
                                socket.emit('joinRoom', { msg: null })
                                document.body.classList.toggle('showMsgOffcanvas')
                                setIsChatOpen(true)
                              }}>
                                <div className="headerIcons">
                                  <img src={mail} alt="currency icon" className='h-100 w-100 object-fit-contain' />
                                </div>
                              </div>
                              <div className="col-auto px-2">

                                <Dropdown >
                                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="iconHeader dropDown-icon">
                                    <div className="headerIcons">
                                      <img src={Avatar} alt="currency icon" className='h-100 w-100 object-fit-contain' />
                                    </div>

                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className='dropDownMenu profileDropMenu'>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.PROFILE
                                        })
                                        navigate(ROUTE_CONST.PROFILE)
                                      }}
                                      className={`dropdownItem ${accountTab?.selectedTab === ACCOUNT_TABS.PROFILE ? 'active' : ''}`} as="button">
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={user} alt="" />
                                        </div>
                                        <div className="DropTxt">
                                          Profile
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.WALLET
                                        })
                                        navigate(ROUTE_CONST.PROFILE)
                                      }} className={`dropdownItem ${accountTab?.selectedTab === ACCOUNT_TABS.WALLET ? 'active' : ''}`} as="button">
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={plus} alt="" />
                                        </div>
                                        <div className="DropTxt">
                                          Wallet
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.AFFILIATE
                                        })
                                        navigate(ROUTE_CONST.PROFILE)
                                      }} className={`dropdownItem ${accountTab?.selectedTab === ACCOUNT_TABS.AFFILIATE ? 'active' : ''}`} as="button">
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={Affiliate} alt="" />
                                        </div>
                                        <div className="DropTxt">
                                          Affiliate
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.DEPOSIT
                                        })
                                        navigate(ROUTE_CONST.PROFILE)
                                      }} className={`dropdownItem ${accountTab?.selectedTab === ACCOUNT_TABS.DEPOSIT ? 'active' : ''}`} as="button">
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={Deposits} alt="" />
                                        </div>
                                        <div className="DropTxt">
                                          Deposits
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.WITHDRAW
                                        })
                                        navigate(ROUTE_CONST.PROFILE)
                                      }} className={`dropdownItem ${accountTab?.selectedTab === ACCOUNT_TABS.WITHDRAW ? 'active' : ''}`} as="button">
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={Withdrawals} alt="" />
                                        </div>
                                        <div className="DropTxt">
                                          Withdrawals
                                        </div>
                                      </div>
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.TRANSACTIONS
                                        })
                                        navigate(ROUTE_CONST.PROFILE)
                                      }} className={`dropdownItem ${accountTab?.selectedTab === ACCOUNT_TABS.TRANSACTIONS ? 'active' : ''}`} as="button">
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={Withdrawals} alt="" />
                                        </div>
                                        <div className="DropTxt">
                                          Transactions
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className={`dropdownItem ${accountTab?.selectedTab === ACCOUNT_TABS.SETTING ? 'active' : ''}`} as="button"

                                      onClick={() => {
                                        setAccountTab({
                                          selectedTab: ACCOUNT_TABS.SETTING
                                        })
                                        navigate(ROUTE_CONST.PROFILE)
                                      }}>
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={Settings} alt="" />
                                        </div>
                                        <div className="DropTxt">
                                          {desktopaccountPageLabels.SETTINGS}
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item className='dropdownItem' as="button" onClick={() => setIsLogoutOpen(true)}>
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="dropIcon">
                                          <img src={Sign} alt="" />
                                        </div>
                                        <div className="DropTxt">
                                          Sign Out
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className='col-auto px-0 ' onClick={() => setIsNotificationOpen(true)}>
                                <div className="headerIcons">
                                  <img src={msgnotification} alt="currency icon" className='h-100 w-100 object-fit-contain' />
                                </div>
                              </div> */}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bottomHeader py-1 d-lg-none">
          <div className="bottomBarBtn">
            <div className="icon" onClick={handleSidebar}>
              <img className='grayImg' src={menuIcongray} alt="menuIcon" />
              <img className='d-none activeImg' src={menuIconActive} alt="menuIcon" />
            </div>
            <div className="txt">{desktopHeader.browse}</div>
          </div>
          <div onClick={() => {
            setGameObject((prev) => ({
              selectedTab: GAME_OPTION_TABS?.LOBBY
            }))
            setQuery({ q: "lobby" })
            navigate(`${ROUTE_CONST.CASINO}?q=${encodeURIComponent('lobby')}`)
          }} className={`bottomBarBtn ${location?.pathname === ROUTE_CONST.CASINO ? 'active' : ''}`}>
            <div className={`icon `}>
              <img className='grayImg' src={casinoIcongray} alt="menuIcon" />
              <img className='d-none activeImg' src={casinoIconActive} alt="menuIcon" />
            </div>
            <div className="txt">{desktopHeader.Casino}</div>
          </div>
          <div onClick={() => navigate(ROUTE_CONST.SPORTS_PAGE)} className={`bottomBarBtn ${location?.pathname === ROUTE_CONST.SPORTS_PAGE ? 'active' : ''}`}>
            <div className="icon">
              <img className='grayImg' src={sportsIcongray} alt="menuIcon" />
              <img className='d-none activeImg' src={sportsIconActive} alt="menuIcon" />
            </div>
            <div className="txt">{desktopHeader.Sports}</div>
          </div>
          <div onClick={() => navigate(ROUTE_CONST.COMING_SOON)} className="bottomBarBtn">
            <div className="icon">
              <img className='grayImg' src={futuresIcongray} alt="menuIcon" />
              <img className='d-none activeImg' src={futuresIconActive} alt="menuIcon" />
            </div>
            <div className="txt">{desktopHeader.futures}</div>
          </div>
          <div onClick={() => navigate(ROUTE_CONST.POKER_PAGE)} className={`bottomBarBtn ${location?.pathname === ROUTE_CONST.POKER_PAGE ? 'active' : ''}`}>
            <div className="icon">
              <img className='grayImg' src={pokerIcongray} alt="menuIcon" />
              <img className='d-none activeImg' src={pokerIconActive} alt="menuIcon" />
            </div>
            <div className="txt">{desktopHeader.poker}</div>
          </div>
          <div onClick={() => navigate(ROUTE_CONST.COMING_SOON)} className="bottomBarBtn">
            <div className="icon tokenIcon">
              <img className='grayImg' src={tokenIcongray} alt="menuIcon" />
              <img className='d-none activeImg' src={tokenIconActive} alt="menuIcon" />
            </div>
            <div className="txt">{desktopHeader.token}</div>
          </div>
          <div onClick={() => navigate(ROUTE_CONST.BONUS)} className={`bottomBarBtn ${location?.pathname === ROUTE_CONST.BONUS ? 'active' : ''}`}>
            <div className="icon tokenIcon">
              <img className='grayImg' src={giftgray} alt="menuIcon" />
              <img className='d-none activeImg giftImg' src={giftgray} alt="menuIcon" />
            </div>
            <div className="txt text-capitalize">{desktopHeader.BONUS?.toLowerCase()}</div>
          </div>
        </div> */}
        {/* bottom nav */}
        <div className="bottom_nav position-fixed w-100 p-3 d-lg-none">
          <div onClick={handleSidebar} className="bottomBarBtn">
            <div className="icon">
              <img className="grayImg" src={menuToggle} alt="menuIcon" />
            </div>
            <div className="txt">Mobile</div>
          </div>
          <div
            onClick={() => navigate(ROUTE_CONST.SPORTS_PAGE)}
            className={`bottomBarBtn ${
              location?.pathname === ROUTE_CONST.SPORTS_PAGE ? "active" : ""
            }`}
          >
            <div className="icon">
              <img className="grayImg" src={Sportsicon} alt="menuIcon" />
            </div>
            <div className="txt">{desktopHeader.Sports}</div>
          </div>
          <div
            onClick={() => {
              setGameObject((prev) => ({
                selectedTab: GAME_OPTION_TABS?.LOBBY,
              }));
              setQuery({ q: "lobby" });
              navigate(
                `${ROUTE_CONST.CASINO}?q=${encodeURIComponent("lobby")}`
              );
            }}
            className={`bottomBarBtn ${
              location?.pathname === ROUTE_CONST.CASINO ? "active" : ""
            }`}
          >
            <div className={`icon `}>
              <img className="grayImg" src={CasinoIcon} alt="menuIcon" />
            </div>
            <div className="txt">{desktopHeader.Casino}</div>
          </div>
          <div className="bottomBarBtn">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="25"
                viewBox="0 0 26 25"
                fill="#2f93dd"
              >
                <path
                  d="M25.2551 21.5047L19.5075 15.6779C20.484 14.1018 21.0508 12.2391 21.0508 10.24C21.0508 4.58427 16.5279 0 10.9488 0C5.36971 0 0.847168 4.58427 0.847168 10.24C0.847168 15.8959 5.36949 20.4797 10.9488 20.4797C13.0949 20.4797 15.0828 19.7996 16.7186 18.6439L22.3978 24.4013C22.7925 24.8009 23.3098 25 23.8265 25C24.3438 25 24.8605 24.8009 25.2558 24.4013C26.0444 23.6011 26.0444 22.3047 25.2551 21.5047ZM10.9488 17.1621C7.17784 17.1621 4.12058 14.0632 4.12058 10.2404C4.12058 6.41759 7.17784 3.31846 10.9488 3.31846C14.72 3.31846 17.777 6.41759 17.777 10.2404C17.777 14.0632 14.72 17.1621 10.9488 17.1621Z"
                  fill="#0E95FA"
                />
              </svg>
            </div>
            <div className="txt">Search</div>
          </div>
          <div className="bottomBarBtn">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="25"
                viewBox="0 0 26 25"
                fill="#2f93dd"
              >
                <path
                  d="M20.1654 0H6.52899C5.02263 0.00180439 3.57849 0.601003 2.51333 1.66616C1.44817 2.73132 0.848972 4.17546 0.847168 5.68182V14.7727C0.848821 16.0821 1.30182 17.3508 2.1298 18.3651C2.95779 19.3794 4.11014 20.0772 5.39263 20.3409V23.8637C5.3926 24.0694 5.44842 24.2713 5.55413 24.4478C5.65985 24.6243 5.8115 24.7687 5.99289 24.8658C6.17429 24.9628 6.37864 25.0088 6.58412 24.9989C6.78961 24.9889 6.98854 24.9233 7.15967 24.8091L13.6881 20.4546H20.1654C21.6717 20.4528 23.1159 19.8536 24.181 18.7884C25.2462 17.7232 25.8454 16.2791 25.8472 14.7727V5.68182C25.8454 4.17546 25.2462 2.73132 24.181 1.66616C23.1159 0.601003 21.6717 0.00180439 20.1654 0ZM17.8926 13.6364H8.80172C8.50034 13.6364 8.2113 13.5167 7.99819 13.3035C7.78508 13.0904 7.66536 12.8014 7.66536 12.5C7.66536 12.1986 7.78508 11.9096 7.99819 11.6965C8.2113 11.4834 8.50034 11.3636 8.80172 11.3636H17.8926C18.194 11.3636 18.4831 11.4834 18.6962 11.6965C18.9093 11.9096 19.029 12.1986 19.029 12.5C19.029 12.8014 18.9093 13.0904 18.6962 13.3035C18.4831 13.5167 18.194 13.6364 17.8926 13.6364ZM20.1654 9.09092H6.52899C6.22761 9.09092 5.93857 8.97119 5.72546 8.75809C5.51235 8.54498 5.39263 8.25594 5.39263 7.95455C5.39263 7.65317 5.51235 7.36413 5.72546 7.15102C5.93857 6.93791 6.22761 6.81819 6.52899 6.81819H20.1654C20.4668 6.81819 20.7558 6.93791 20.9689 7.15102C21.182 7.36413 21.3017 7.65317 21.3017 7.95455C21.3017 8.25594 21.182 8.54498 20.9689 8.75809C20.7558 8.97119 20.4668 9.09092 20.1654 9.09092Z"
                  fill="#0E95FA"
                />
              </svg>
            </div>
            <div className="txt">Chat</div>
          </div>
        </div>
      </div>
      <AuthModal />
      <RainbowKitProvider>
        <LogoutModal />
      </RainbowKitProvider>
      <ReferralModal />
      <FiatModal
        showFiatModal={showFiatModal}
        setShowFiatModal={setShowFiatModal}
      />
      <AddCurrencyModal
        showFavoriteModal={showFavoriteModal}
        setShowFavoriteModal={setShowFavoriteModal}
      />
      <UnAuthCurrencyModal
        isOpen={showLanguage}
        setIsOpen={setShowLanguage}
        selectedTab={languageSelectedTab}
        setSelectedTab={setLanguageSelectedTab}
      />
      <Notification />
      <MessageOffcanvas />
      <ResultModal />
      <WalletModal />
      {/* <SpinWheel/> */}
      {/* <SpinWheelModal/> */}
      <DepositeMessage
        showDepositMsg={showDepositMsg}
        setShowDepositMsg={setShowDepositMsg}
        depositMsgData={depositMsgData}
      />
      <FreeSpinMsgModal />
      <LoginSuccessModal />
      <DBLRackBack />
      <DBLBonusHistory />
    </>
  );
};

export default Header;
