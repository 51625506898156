import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
      <div className='privacy-policy-section'>
        <div className="heading">
        Privacy Policy for memecoin.io
        </div>
        <div className="sub-txt">
        Last updated: 1.4.2025
        </div>
        <div className="sub-heading">
        1. Introduction
        </div>
        <div className="sub-txt">
        Welcome to memecoin.io. Your privacy is critically important to us. This Privacy Policy outlines how we collect, use, protect, and disclose your personal data. We comply with international standards including the General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and Australian Privacy Principles (APPs).
        </div>
        <div className="sub-heading">
          2. Data Collection and Usage
        </div>
        <div className="sub-heading">
          Information We Collect:
        </div>
        <div className="sub-txt">
          <ul>
            <li>Personal Identification Information (name, email, phone number, age, Telegram ID, etc.)</li>
            <li>Crypto wallet addresses</li>
            <li>Account and financial transaction details</li>
            <li>IP address, cookies, device identifiers</li>
            <li>Usage data, analytics</li>
            <li>Data provided during KYC (Know Your Customer) processes via third-party providers</li>
          </ul>
        </div>
        <div className="sub-heading">
          How We Use Your Data:
        </div>
        <div className="sub-txt">
          <ul>
            <li>Account setup and management</li>
            <li>Transactions and payments processing  </li>
            <li>Enhancing user experience</li>
            <li>Marketing and communications (with consent)</li>
            <li>Legal compliance and fraud prevention</li>
          </ul>
        </div>
        <div className="sub-heading">
        3. KYC (Know Your Customer)
        </div>
        <div className="sub-txt">
        Our KYC verification is conducted by third-party agencies. These third-party platforms independently collect and process personal data according to their own privacy policies. memecoin.io has no direct control or obligation regarding the data collection practices of these third-party agencies. However, we may retain certain information provided back to us by these third-party agencies for compliance and security purposes
        </div>
        <div className="sub-heading">
          4. Legal Basis for Processing
        </div>
        <div className="sub-txt">
          We process your data based on:
        </div>
        <div className="sub-txt">
          <ul>
            <li>Consent</li>
            <li>Contractual obligations</li>
            <li>Legal obligations</li>
            <li>Legitimate interests</li>
          </ul>
        </div>
        <div className="sub-heading">
        5. Data Sharing
        </div>
        <div className="sub-txt">
        We may share data with:
        </div>
        <div className="sub-txt">
          <ul>
            <li>Payment service providers</li>
            <li>Third-party KYC verification agencies</li>
            <li>Analytics and marketing partners</li>
            <li>Regulatory authorities (if required by law)</li>
          </ul>
        </div>
        <div className="sub-txt">
        We never sell or rent your personal data.
        </div>
        <div className="sub-heading">
        6. International Data Transfers
        </div>
        <div className="sub-txt">
        Your data may be transferred internationally in compliance with GDPR and other applicable laws. We ensure appropriate safeguards, such as standard contractual clauses, are in place to protect your data.
        </div>
        <div className="sub-heading">
        7. Data Security
        </div>
        <div className="sub-txt">
        We implement industry-standard security measures to protect your personal data against unauthorized access, disclosure, or misuse.
        </div>
        <div className="sub-heading">
        8. Data Retention
        </div>
        <div className="sub-txt">
        Your personal data is retained only for as long as necessary for purposes outlined in this policy, compliance with legal obligations, or as permitted by applicable laws.
        </div>
        <div className="sub-heading">
        9. User Rights
        </div>
        <div className="sub-txt">
        You have the right to:
        </div>
        <div className="sub-txt">
          <ul>
            <li>Access your data</li>
            <li>Rectify inaccurate data</li>
            <li>Request deletion ("right to be forgotten")</li>
            <li>Restrict or object to processing</li>
            <li>Data portability</li>
            <li>Withdraw consent at any time</li>
          </ul>
        </div>
        <div className="sub-txt">
        For exercising these rights, please contact us at privacy@memecoin.io.
        </div>
        <div className="sub-heading">
        10. Cookies
        </div>
        <div className="sub-txt">
        We use cookies and similar technologies to improve your experience. You may control cookie usage through your browser settings.
        </div>
        <div className="sub-heading">
        11. Third-party Links

        </div>
        <div className="sub-txt">
        Our website may contain links to third-party sites. We are not responsible for their privacy practices. Please review their policies separately.

        </div>
        <div className="sub-heading">
        12. Children's Privacy

        </div>
        <div className="sub-txt">
        memecoin.io is not directed toward individuals under 18. We do not knowingly collect data from minors.

        </div>
        <div className="sub-heading">
        13. Changes to This Privacy Policy

        </div>
        <div className="sub-txt">
        We may periodically update our policy. Changes will be notified via email and website notifications. Please review our policy regularly.

        </div>

        <div className="sub-heading">
        14. Contact Us
        </div>
        <div className="sub-txt">
        For queries regarding your personal data or this policy, please contact:
        </div>
        <div className="sub-txt">
        <span className='text-white fw-normal'>Email:</span> privacy@memecoin.io
        </div>
        <div className="sub-txt">
        <span className='text-white fw-normal'>Address:</span>  [Insert Registered Business Address]
        </div>
        <div className="sub-txt">
        Thank you for trusting memecoin.io with your personal data.
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
