import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import dblImg from "../../assets/images/DBL.png";
import lockPng from "../../assets/images/lock.png";
import leftarow from "../../assets/images/leftarow.png";
import USDT from '../../assets/images/usdt.svg'
import closeModal from "../../assets/images/closeModal.png";
import { useClaimBonus } from "../../hooks/useClaimBonus";
import { useAuth } from "../../hooks/useAuth";
import { getAPIAuth } from "../../services/apiInstance";
import moment from "moment";
import NoData from "../noData/NoData";

const DBLBonusHistory = () => {
  const { claimBonusObject, setClaimBonusObject } = useClaimBonus();
  const [data, setData] = useState({});
  const { token } = useAuth();

  const handleClose = () => {
    setClaimBonusObject((prev) => ({
      ...prev,
      isClaimHistoryOpen: false,
    }));
  };

  const getBonusHistory = async () => {
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
    try {
      const res = await getAPIAuth(
        `user/get-bonus-history?type=${
          claimBonusObject?.selectedTab === "deposit" ||
          claimBonusObject?.selectedTab === "daily"
            ? "lockedBonus"
            : claimBonusObject?.selectedTab
        }`,
        token
      );
      if (res?.data?.status) {
        setData(res?.data?.data)
      } else {
        setData({});
      }
    } catch (error) {
      setData({});
    }
  };

  useEffect(() => {
    if (claimBonusObject?.selectedTab && claimBonusObject?.isClaimHistoryOpen) {
      getBonusHistory();
    }
  }, [claimBonusObject?.selectedTab, claimBonusObject?.isClaimHistoryOpen]);



  return (
    <>
      <Modal
        className="modalSection"
        centered
        show={claimBonusObject?.isClaimHistoryOpen}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>
              <img src={leftarow} alt="" className="h-100 w-100" />
            </span>
            {claimBonusObject?.selectedTab === "weekly"
              ? "Weekly Bonus history"
              : claimBonusObject?.selectedTab === "monthly"
              ? "Monthly Bonus history"
              : claimBonusObject?.selectedTab === "daily"
              ? "Daily Bonus history"
              : claimBonusObject?.selectedTab === "deposit"
              ? "Deposit Bonus history"
              : "USDT Bonus history"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="row">
            <div className="col-12 BounseTable table-responsive mt-0">
              <Table>
                <thead>
                  <tr>
                    <th className="text-start">Date</th>
                    <th className="text-center">Type</th>
                    {/* <th className='text-center'>Status</th> */}
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length ? (
                    data?.map((item) => (
                      <tr key={item?.id}>
                        <td className="text-start">
                          <div>
                            <div>
                              {moment(item?.createdAt).format("h:mm:ss A")}
                            </div>
                            <div>{moment(item?.createdAt).format("L")}</div>
                          </div>
                        </td>
                        <td className="text-center ">
                          <div className="d-flex align-items-center justify-content-center h-100">
                            {item?.type}
                          </div>
                        </td>
                        {/* <td className="text-center ">
                          <div className="d-flex align-items-center justify-content-center h-100">
                            {item?.paymentstatus}
                          </div>
                        </td> */}
                        <td className="text-end">
                          <div className="amount">
                            
                            <span className="amountImg">
                              <img src={USDT} alt="dblImg" />
                            </span>
                            +$ {item?.bonus_amt}{" "}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <NoData />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DBLBonusHistory;
