import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import userWallet from "./../../assets/images/wallet.svg";
import userWithdraw from "./../../assets/images/withdraw.svg";
import userTransactions from "./../../assets/images/transactions.svg";
import buy from "./../../assets/images/buy.svg";
import swap from "./../../assets/images/swap.svg";
import rollover from "./../../assets/images/rollover.svg";
import bitHistory from "./../../assets/images/bit-history.svg";
import deposit from "./../../assets/images/deposit.svg";
import totalBalance from "./../../assets/images/total-balance.svg";
import rupeeIcon from "./../../assets/images/rupee_icon.svg";
import USDT from "./../../assets/images/usdt.svg";
import BTC from "./../../assets/images/btc.svg";
import BNB from "./../../assets/images/bnb.svg";
import ETH from "./../../assets/images/eth.svg";
import TURBO from "./../../assets/images/turbo.svg";
import USDC from "./../../assets/images/usdc.svg";
import AVAX from "./../../assets/images/avax.svg";
import CBANK from "./../../assets/images/cbank.svg";
import CAF from "./../../assets/images/caf.svg";
import cairo from "./../../assets/images/cairo.svg";
import thunderIcon from "./../../assets/images/thunder-icon.svg";
import giftBox from "./../../assets/images/gift.svg";
import barImage from "./../../assets/images/barCode.svg";
import EUR from "./../../assets/images/eur.svg";
import sepaInstant from "./../../assets/images/sepa-instant.svg";
import Euro from "./../../assets/images/euro.svg";
import swapIcon from "./../../assets/images/swap-icon.svg";
import fileEmpty from "../../assets/images/fileEmpty.svg";
import { useSelector } from "react-redux";
import { getAPIAuth } from "../../services/apiInstance";
import { useAuth } from "../../hooks/useAuth";
import DatePicker from "react-datepicker";
import { Spinner } from "react-bootstrap";
const betType = [
  {
    type: "Casino",
    value: "casino",
  },
  {
    type: "Sports",
    value: "sports",
  },
];

const transactionsDate = [
  {
    type: "Past 30 Days",
    value: "30",
  },
  {
    type: "Past 60 Days",
    value: "60",
  },
  {
    type: "Past 7 Days",
    value: "7",
  },
  {
    type: "Past 24 Hours",
    value: "24h",
  },
  {
    type: "Past 90 Days",
    value: "90",
  },
];

const BetHistoryTab = () => {
  const userDetails = useSelector((state) => state.userDetails);
  const { token } = useAuth();
  const [selectedBetType, setSelectedBetType] = useState({
    type: "Casino",
    value: "casino",
  });

  const [selectedTransactionDate, setSelectedTransactionDate] = useState({
    type: "Past 24 Hours",
    value: "24h",
  });
  const [loading, setLoading] = useState(true);
  const [availableCryptoSearach, setAvailableCryptoSearch] = useState("");
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetsList, setAssestList] = useState([]);
  const [showDateDropDown, setShowDropDown] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [betHistory, setBetHistory] = useState([]);
  const [startDate, endDate] = dateRange;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const getAvaliableCrypto = async () => {
    try {
      const res = await getAPIAuth(
        `crypto/get-crypto?userId=${
          userDetails?.id
        }&crypto=${availableCryptoSearach.toUpperCase()}`,
        token
      );

      if (res.data.success) {
        setAssestList(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBetHistoryList = async () => {
    try {
      setLoading(true);
      let url = `user/getBetHistory/${selectedBetType.value}?page=${page}`;

      if (selectedAsset) {
        url = `${url}&asset=${selectedAsset?.name}`;
      }

      if (selectedTransactionDate) {
        if (selectedTransactionDate.value === "custom") {
          url = `${url}&startDate=${startDate}&endDate=${endDate}`;
        } else {
          url = `${url}&dateFilter=${selectedTransactionDate?.value}`;
        }
      }
      const res = await getAPIAuth(url);
      if (res.data.success) {
        setBetHistory(res.data.data);
        if (res.data.data.length) {
          setTotalPages(Math.ceil(res.data.totalItems / 10));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userDetails) {
      getAvaliableCrypto();
    }
  }, [userDetails, availableCryptoSearach]);

  useEffect(() => {
    if (startDate && endDate && !showDateDropDown) {
      setSelectedTransactionDate({ type: "Custom", value: "custom" });
    }
    if (startDate && endDate) {
      setSelectedTransactionDate({ type: "Custom", value: "custom" });
    }
  }, [startDate, endDate, showDateDropDown]);

  useEffect(() => {
    getBetHistoryList();
  }, [selectedBetType, selectedAsset, selectedTransactionDate, page]);

  return (
    <>
      <div className="betHistory_sec">
        <div className="betHistory_row p-3 bg_main_color">
          <div className="row align-items-center g-4">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="select_drop">
                <div className="btn-group w-100">
                  <button
                    className="d-flex justify-content-between  px-3 py-2 rounded_bg align-items-center border-0 shadow-none dropdown-toggle w-100 rounded-pill"
                    type="button"
                    id="defaultDropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center gap-2 text-white">
                      {selectedBetType?.type}
                    </div>
                  </button>
                  <ul
                    className="dropdown-menu w-100 rounded_bg p-3"
                    aria-labelledby="defaultDropdown"
                  >
                    <div className="currency_search_list overflow-y-auto">
                      <ul class="m-0  d-flex flex-column gap-2 p-2 mt-3">
                        {betType.map((item) => {
                          return (
                            <li
                              onClick={() => setSelectedBetType(item)}
                              key={item.value}
                              className={`cursor-pointer d-flex justify-content-between align-items-center dropList ${
                                item.value === selectedBetType?.value
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              <div className="d-flex align-items-center gap-2">
                                {item.type}
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id={item.value}
                                  // checked={
                                  //   item.value ===
                                  //   selectedTransactionType?.value
                                  //     ? true
                                  //     : false
                                  // }

                                  checked={true}
                                />
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="select_drop">
                <div className="btn-group w-100">
                  <button
                    className="d-flex justify-content-between px-3 py-2 rounded_bg align-items-center border-0 shadow-none dropdown-toggle w-100 rounded-pill"
                    type="button"
                    id="defaultDropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center gap-2 text-white">
                      {selectedAsset?.icon ? (
                        <img src={selectedAsset?.icon} alt="" />
                      ) : (
                        ""
                      )}
                      {selectedAsset?.name ? selectedAsset?.name : "All Assets"}
                    </div>
                  </button>
                  <ul
                    className="dropdown-menu w-100 rounded_bg p-3"
                    aria-labelledby="defaultDropdown"
                  >
                    <div className="searchInpDrop position-relative">
                      <input
                        onChange={(e) =>
                          setAvailableCryptoSearch(e.target.value)
                        }
                        type="text"
                        placeholder="Search"
                      />

                      <button
                        type="button"
                        className="searchImgg border-0 shadow-none p-0 m-0 bg-transparent"
                      >
                        <svg
                          stroke="#93A6C4"
                          height="22px"
                          widths="22px"
                          fill="#93A6C4"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
                        </svg>
                      </button>
                    </div>
                    <div className="currency_search_list overflow-y-auto">
                      <ul class="m-0  d-flex flex-column gap-2 p-2 mt-3">
                        {assetsList.map((item) => {
                          return (
                            <li
                              onClick={() => setSelectedAsset(item)}
                              key={item._id}
                              className={`cursor-pointer d-flex justify-content-between align-items-center dropList ${
                                item.name === selectedAsset?.name
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              <div className="d-flex align-items-center gap-2">
                                <img src={item.icon} alt="" />
                                {item.name}
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  checked={
                                    item.name === selectedAsset?.name
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {/* <div class="dropFooter mx-1 mb-1 d-flex align-items-center justify-content-between">
                      <div class="footerTxt text-white fw-bold">
                        Hide 0 Balance
                      </div>
                      <div>
                        <form class="">
                          <div class="footerToggle form-check form-switch">
                            <input
                              type="checkbox"
                              id="custom-switch"
                              class="form-check-input"
                            />
                          </div>
                        </form>
                      </div>
                    </div> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="select_drop">
                <div className="btn-group w-100 relative">
                  {showDateDropDown ? (
                    <div
                      className="custom-backdrop"
                      onClick={() => setShowDropDown(false)}
                    />
                  ) : (
                    <></>
                  )}
                  <button
                    onClick={() => setShowDropDown((prv) => !prv)}
                    className={`d-flex justify-content-between  px-3 py-2 rounded_bg align-items-center border-0 shadow-none dropdown-toggle w-100 rounded-pill  ${
                      showDateDropDown ? "show" : ""
                    }  `}
                    type="button"
                    id="defaultDropdown"
                  >
                    <div className="d-flex align-items-center gap-2 text-white">
                      {selectedTransactionDate?.type}
                    </div>
                  </button>
                  <ul
                    className={`dropdown-menu w-100 rounded_bg p-3  ${
                      showDateDropDown ? "show" : ""
                    } `}
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      margin: "0px",
                      transform: "translate3d(0px, 48px, 0px)",
                    }}
                  >
                    <div>
                      <div className="currency_search_list overflow-y-auto">
                        <ul
                          className={`m-0  d-flex flex-column gap-2 p-0 mt-3`}
                        >
                          {transactionsDate.map((item) => {
                            return (
                              <li
                                onClick={() => setSelectedTransactionDate(item)}
                                key={item.value}
                                className={`cursor-pointer d-flex justify-content-between align-items-center dropList ${
                                  item.value === selectedTransactionDate?.value
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  {item.type}
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id={item.value}
                                  />
                                </div>
                              </li>
                            );
                          })}
                          <li
                            className={`cursor-pointer d-flex justify-content-between align-items-center dropList `}
                          >
                            <div className="d-flex align-items-center gap-2">
                              <DatePicker
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                  setDateRange(update);
                                }}
                                maxDate={new Date()}
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select a date"
                                selectsRange={true}
                                isClearable={true}
                              />
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefaultjjjd2"
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="rollover_table mt-5">
            <label htmlFor="" className="form-label">
              <span className="pe-2">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  version="1.1"
                  viewBox="0 0 16 16"
                  height="20px"
                  width="20px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 1.5c-1.736 0-3.369 0.676-4.596 1.904s-1.904 2.86-1.904 4.596c0 1.736 0.676 3.369 1.904 4.596s2.86 1.904 4.596 1.904c1.736 0 3.369-0.676 4.596-1.904s1.904-2.86 1.904-4.596c0-1.736-0.676-3.369-1.904-4.596s-2.86-1.904-4.596-1.904zM8 0v0c4.418 0 8 3.582 8 8s-3.582 8-8 8c-4.418 0-8-3.582-8-8s3.582-8 8-8zM7 11h2v2h-2zM7 3h2v6h-2z"></path>
                </svg>
              </span>
              Fiat Deposit issues or Disputes
            </label>
            <div className="table-responsive w-100 table_sec">
              <table class="table align-middle w-100">
                <thead>
                  <tr>
                    <th scope="col">Game Name</th>
                    <th scope="col">Time</th>
                    <th scope="col">Wagered Amount</th>
                    <th scope="col">Payout</th>
                    <th scope="col">Profit</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={6} className="data_not_found">
                        <div className="d-flex  justify-content-center align-items-center py-5 ">
                          <Spinner />
                        </div>
                      </td>
                    </tr>
                  ) : betHistory.length ? (
                    betHistory?.map((item) => (
                      <tr>
                        <td>{item.gameName}</td>
                        <td>
                          {new Date(item.createdAt).toLocaleTimeString(
                            "en-US",
                            { hour12: false }
                          )}
                        </td>
                        <td>{item.amountInUSDT.toFixed(2)}</td>
                        <td>{item.payout.toFixed(2)}</td>
                        <td
                          // className={`${item.profit  ? ".text-danger" : ""}`}
                          style={{
                            color: item.profit < 0 ? "red" : "#ffff",
                          }}
                        >
                          {item.profit.toFixed(2)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="data_not_found">
                        <div className="d-flex flex-column gap-3 py-5">
                          <div className="empty_img">
                            <img
                              src={fileEmpty}
                              alt=""
                              style={{ height: "60px" }}
                              className="img-fluid"
                            />
                          </div>
                          <div className="text-center">
                            <div>No info yet</div>
                            <div>Invite friends to join you now!</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {loading || !betHistory.length ? (
              ""
            ) : (
              <div className="nextPrvButtonDiv">
                <button
                  className={`  ${page === 1 ? "disable" : ""} `}
                  onClick={() => {
                    if (page === 1) return;
                    setPage((prv) => prv - 1);
                  }}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="18px"
                    width="18px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                  </svg>
                </button>
                <button
                  className={`  ${page === totalPages ? "disable" : ""} `}
                  onClick={() => {
                    if (page === totalPages) return;
                    setPage((prv) => prv + 1);
                  }}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="18px"
                    width="18px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BetHistoryTab;
