
import React from "react";
import { Link } from "react-router-dom";
import userWallet from "./../../assets/images/wallet.svg";
import userWithdraw from "./../../assets/images/withdraw.svg";
import userTransactions from "./../../assets/images/transactions.svg";
import buy from "./../../assets/images/buy.svg";
import swap from "./../../assets/images/swap.svg";
import rollover from "./../../assets/images/rollover.svg";
import bitHistory from "./../../assets/images/bit-history.svg";
import deposit from "./../../assets/images/deposit.svg";
import totalBalance from "./../../assets/images/total-balance.svg";
import rupeeIcon from "./../../assets/images/rupee_icon.svg";
import USDT from "./../../assets/images/usdt.svg";
import BTC from "./../../assets/images/btc.svg";
import BNB from "./../../assets/images/bnb.svg";
import ETH from "./../../assets/images/eth.svg";
import TURBO from "./../../assets/images/turbo.svg";
import USDC from "./../../assets/images/usdc.svg";
import AVAX from "./../../assets/images/avax.svg";
import CBANK from "./../../assets/images/cbank.svg";
import CAF from "./../../assets/images/caf.svg";
import cairo from "./../../assets/images/cairo.svg";
import thunderIcon from "./../../assets/images/thunder-icon.svg";
import giftBox from "./../../assets/images/gift.svg";
import barImage from "./../../assets/images/barCode.svg";
import EUR from "./../../assets/images/eur.svg";
import sepaInstant from "./../../assets/images/sepa-instant.svg";
import Euro from "./../../assets/images/euro.svg";
import swapIcon from "./../../assets/images/swap-icon.svg";
import fileEmpty from "../../assets/images/fileEmpty.svg";

const SwapTab = () => {
  return (
    <>
        <div className="swap_sec">

                  <div className="swap_row p-3 bg_main_color">
                    <div className="mb-3">
                      <div className="d-flex align-items-center justify-content-between gap-3 mb-1">
                        <label
                          for=""
                          className="form-label"
                          style={{ color: "#6a798c" }}
                        >
                          From
                        </label>
                        <div style={{ color: "#6a798c" }}>≈BGN 0.00</div>
                      </div>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="rounded_bg border-0 shadow-none rounded-pill px-3 py-2 w-100 text-white"
                          id=""
                          value="SHIP"
                        />
                        <div className="swap_select position-absolute col-lg-3 col-md-6 col-6">
                          <div className="select_drop">
                            <div className="btn-group w-100">
                              <button
                                className="d-flex justify-content-between text-uppercase px-3 py-2 rounded_bg align-items-center border shadow-none dropdown-toggle w-100 rounded-pill"
                                type="button"
                                id="defaultDropdown"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="true"
                                aria-expanded="false"
                              >
                                <div className="d-flex align-items-center gap-2 text-white">
                                  <span>
                                    <img
                                      src={BTC}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </span>
                                  BTC
                                </div>
                              </button>
                              <ul
                                className="dropdown-menu w-100 rounded_bg p-3"
                                aria-labelledby="defaultDropdown"
                              >
                                <div className="searchInpDrop position-relative">
                                  <input type="text" placeholder="Search" />
                                  <button
                                    type="button"
                                    className="searchImgg border-0 shadow-none p-0 m-0 bg-transparent"
                                  >
                                    <svg
                                      stroke="#93A6C4"
                                      height="22px"
                                      widths="22px"
                                      fill="#93A6C4"
                                      stroke-width="0"
                                      viewBox="0 0 512 512"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="currency_search_list overflow-y-auto">
                                  <ul class="m-0  d-flex flex-column gap-2 p-2 mt-3">
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={BTC} alt="" />
                                        BTC
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={ETH} alt="" />
                                        ETH
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={USDT} alt="" />
                                        USDT
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={USDC} alt="" />
                                        trx
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={BNB} alt="" />
                                        bnb
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={BTC} alt="" />
                                        BTC
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={ETH} alt="" />
                                        ETH
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={USDT} alt="" />
                                        USDT
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={USDC} alt="" />
                                        trx
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={BNB} alt="" />
                                        bnb
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="dropFooter mx-1 mb-1 d-flex align-items-center justify-content-between">
                                  <div class="footerTxt text-white fw-bold">
                                    Hide 0 Balance
                                  </div>
                                  <div>
                                    <form class="">
                                      <div class="footerToggle form-check form-switch">
                                        <input
                                          type="checkbox"
                                          id="custom-switch"
                                          class="form-check-input"
                                        />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="input_percent">
                          <ul className="p-0 mt-3 row align-items-center gy-2">
                            <li className="col-lg-3 col-md-3 col-6 px-1">
                              <button
                                type="button"
                                class="w-100 border-0 shadow-none py-2 px-3 rounded_bg text-white rounded-pill"
                              >
                                25%
                              </button>
                            </li>
                            <li className="col-lg-3 col-md-3 col-6 px-1">
                              <button
                                type="button"
                                class="w-100 border-0 shadow-none py-2 px-3 rounded_bg text-white rounded-pill"
                              >
                                25%
                              </button>
                            </li>
                            <li className="col-lg-3 col-md-3 col-6 px-1">
                              <button
                                type="button"
                                class="w-100 border-0 shadow-none py-2 px-3 rounded_bg text-white rounded-pill"
                              >
                                25%
                              </button>
                            </li>
                            <li className="col-lg-3 col-md-3 col-6 px-1">
                              <button
                                type="button"
                                class="w-100 border-0 shadow-none py-2 px-3 rounded_bg text-white rounded-pill"
                              >
                                25%
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="swap_line d-flex justify-content-center w-100">
                          <div class="divider-line"></div>
                          <button className="rounded_bg shadow-none rounded-circle d-flex align-items-center justify-content-center">
                            <img src={swapIcon} alt="" className="img-fluid" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="d-flex align-items-center justify-content-between gap-3 mb-1">
                        <label
                          for=""
                          className="form-label"
                          style={{ color: "#6a798c" }}
                        >
                          From
                        </label>
                        <div style={{ color: "#6a798c" }}>≈BGN 0.00</div>
                      </div>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="rounded_bg border-0 shadow-none rounded-pill px-3 py-2 w-100 text-white"
                          id=""
                          value="SHIP"
                        />
                        <div className="swap_select position-absolute col-lg-3 col-md-6 col-6">
                          <div className="select_drop">
                            <div className="btn-group w-100">
                              <button
                                className="d-flex justify-content-between text-uppercase px-3 py-2 rounded_bg align-items-center border shadow-none dropdown-toggle w-100 rounded-pill"
                                type="button"
                                id="defaultDropdown"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="true"
                                aria-expanded="false"
                              >
                                <div className="d-flex align-items-center gap-2 text-white">
                                  <span>
                                    <img
                                      src={BTC}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </span>
                                  BTC
                                </div>
                              </button>
                              <ul
                                className="dropdown-menu w-100 rounded_bg p-3"
                                aria-labelledby="defaultDropdown"
                              >
                                <div className="searchInpDrop position-relative">
                                  <input type="text" placeholder="Search" />
                                  <button
                                    type="button"
                                    className="searchImgg border-0 shadow-none p-0 m-0 bg-transparent"
                                  >
                                    <svg
                                      stroke="#93A6C4"
                                      height="22px"
                                      widths="22px"
                                      fill="#93A6C4"
                                      stroke-width="0"
                                      viewBox="0 0 512 512"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="currency_search_list overflow-y-auto">
                                  <ul class="m-0  d-flex flex-column gap-2 p-2 mt-3">
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={BTC} alt="" />
                                        BTC
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={ETH} alt="" />
                                        ETH
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={USDT} alt="" />
                                        USDT
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={USDC} alt="" />
                                        trx
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={BNB} alt="" />
                                        bnb
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={BTC} alt="" />
                                        BTC
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={ETH} alt="" />
                                        ETH
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={USDT} alt="" />
                                        USDT
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={USDC} alt="" />
                                        trx
                                      </div>
                                    </li>
                                    <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                      <div class="d-flex align-items-center gap-2">
                                        <img src={BNB} alt="" />
                                        bnb
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="dropFooter mx-1 mb-1 d-flex align-items-center justify-content-between">
                                  <div class="footerTxt text-white fw-bold">
                                    Hide 0 Balance
                                  </div>
                                  <div>
                                    <form class="">
                                      <div class="footerToggle form-check form-switch">
                                        <input
                                          type="checkbox"
                                          id="custom-switch"
                                          class="form-check-input"
                                        />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="total_deposit mt-5">
                      <div className="d-flex align-items-center justify-content-between pb-3 gap-3">
                        <div style={{ color: "#6a798c" }}>
                          Deposit Balance: 0
                        </div>
                        <div style={{ color: "#6a798c" }}>Bonus Balance: 0</div>
                      </div>
                      <ul className="deposit_bln_list pt-3 p-0 d-flex flex-column gap-3">
                        <li className="d-flex align-items-center justify-content-between gap-3">
                          <div>Rate</div>
                          <div>1 USDT ≈ 1 BCD</div>
                        </li>
                        <li className="d-flex align-items-center justify-content-between gap-3">
                          <div>Estimated Time</div>
                          <div>30 Seconds</div>
                        </li>
                        <li className="d-flex align-items-center justify-content-between gap-3">
                          <div>Swap fee</div>
                          <div>0 USDT</div>
                        </li>
                      </ul>
                    </div>
                    <div class="col-12 mt-5">
                      <button
                        type="button"
                        class="rounded-pill border-0 shadow-none text-white py-2 px-3 text-center w-100"
                        style={{
                          background:
                            "linear-gradient(45deg, #3dacff, #21a0ff)",
                        }}
                      >
                        Swap Now
                      </button>
                    </div>
                  </div>
                </div>
    </>
  )
}

export default SwapTab